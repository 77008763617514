import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import { COLOR_REACHABILITY_POLYGONS,OUTLINE_REACHABILITY_POLYGONS } from './constants';

type PopupObject = {
    title: string;
    collapseEnabled :boolean;
    content: any;
};

const createPopupTemplate = (
    title: string,
    content: any = null,
    collapseEnabled   : boolean
): PopupObject => {
    return {
        title,
        content,
        collapseEnabled
    };
};

export const styleReachabilityLayer = (filledColor: string, outlineColor: string): SimpleRenderer => {
    return new SimpleRenderer({
        symbol: new SimpleFillSymbol({
            color: filledColor,
            outline: {
                color: outlineColor,
                width: 1,
            },
        }),
    });
};

export const createReachabilityLayer = (
    copyright: string,
    renderer: SimpleRenderer,
    popupTempl?: PopupObject
): GeoJSONLayer => {
    return new GeoJSONLayer({
        copyright,
        renderer: renderer,
        popupTemplate: popupTempl
    });
};

const popupContent4Stats = [
    {
        type: "expression",
        expressionInfo: {
            expression: `
            var attributes = {};
            attributes['Eta 0-17']=$feature.stats_eta_17_totale;
            attributes['Eta 18-49']=$feature.stats_eta_18_49_totale;
            attributes['Eta 50-64']=$feature.stats_eta_50_64_totale;
            attributes['Eta 65-74']=$feature.stats_eta_65_74_totale;
            attributes['Eta 75-..']=$feature.stats_eta_75_totale;
            attributes['Femmine']=$feature.stats_sesso_f;
            attributes['Maschi']=$feature.stats_sesso_m;
            return {
            type: "media",
            attributes: attributes,
            mediaInfos: [{
                type: "columnchart",
                value: {
                fields: ['Eta 0-17','Eta 18-49','Eta 50-64','Eta 65-74','Eta 75-..']
                }
            }, {
                type: "piechart",
                value: {
                fields: ['Femmine','Maschi']
                }
            }]
            }`
        }
    }
];

const statsPopupTemplate = createPopupTemplate("totale: {stats_totale}",popupContent4Stats, true);

const reachabilityLayer = createReachabilityLayer(
    "reachability",
    styleReachabilityLayer(COLOR_REACHABILITY_POLYGONS,OUTLINE_REACHABILITY_POLYGONS),
    statsPopupTemplate
);

export const calculations = {
    reachability: {
      layer: reachabilityLayer,
    }
};
