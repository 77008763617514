import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { deviceSizes } from '../../responsive/deviceSizes';
import { NavbarContext } from '../../store/navbar-context';
import MapContent from './MapContent';
import SidebarAnalyses from '../Sidebars/SidebarAnalysis/SidebarAnalysis';
import SidebarTools from '../Sidebars/SidebarTools/SidebarTools';
import SidebarContainer from '../Sidebars/SidebarContainer';
import  WorkspaceContextProvider  from '../../store/workspace-context';

import styles from './MapPage.module.css';

const MapPage: React.FC = () => {
    const [mobileNavValue, setMobileNavValue] = useState(0);
    const navbarCtx = useContext(NavbarContext);
    const isMobile = useMediaQuery({ maxWidth: deviceSizes.mobile });

    // when the element is rendered check the size screen
    // if it's mobile then disactivate analysis sidebar ( we will see on mobile active as default toolbar sidebar )
    useEffect(() => {
        if (isMobile && mobileNavValue === 0) {
            navbarCtx.setAnalysisSidebarActive(false);
        } else if (isMobile && mobileNavValue === 1) {
            navbarCtx.setToolSidebarActive(false);
            navbarCtx.setAnalysisSidebarActive(true);
        }
    }, [isMobile]);

    return (
        <WorkspaceContextProvider>
            <div className={styles.wrapper}>
                {!isMobile && <SidebarTools />}
                <MapContent />
                {!isMobile && <SidebarAnalyses />}
                {isMobile && (
                    <SidebarContainer
                        mobileNavValue={mobileNavValue}
                        setMobileNavValue={setMobileNavValue}
                    />
                )}
            </div>
        </WorkspaceContextProvider>
    );
};

export default MapPage;
