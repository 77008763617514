import React, { useMemo, useState, Dispatch, SetStateAction } from 'react';

type NavbarContextObj = {
    toolSidebarActive: boolean;
    setToolSidebarActive: Dispatch<SetStateAction<boolean>>;
    analysisSidebarActive: boolean;
    setAnalysisSidebarActive: Dispatch<SetStateAction<boolean>>;
};

const navCtxDefaultValue: NavbarContextObj = {
    toolSidebarActive: true,
    setToolSidebarActive: (state) => { }, // noop default callback
    analysisSidebarActive: true,
    setAnalysisSidebarActive: (state) => { }, // noop default callback
};
export const NavbarContext = React.createContext<NavbarContextObj>(navCtxDefaultValue);

const NavbarContextProvider: React.FC = (props) => {
    const [toolSidebarActive, setToolSidebarActive] = useState(true);
    const [analysisSidebarActive, setAnalysisSidebarActive] = useState(true);

    const { children } = props;


    const contextValue: NavbarContextObj = useMemo(
        (): NavbarContextObj => ({
            toolSidebarActive,
            setToolSidebarActive,
            analysisSidebarActive,
            setAnalysisSidebarActive,
        }),
        [toolSidebarActive, analysisSidebarActive]
    );

    return <NavbarContext.Provider value={contextValue}>{children}</NavbarContext.Provider>;
};

export default NavbarContextProvider;
