import React from "react";
import styles from "./Footer.module.css";
import configData from "../config.json";

import coloureeLogo from "../data/logo/Logo_Colouree.svg";

const Footer: React.FC = () => {
  const email1 = `mailto: ${configData.FOOTER.EMAIL_1}`;
  const email2 = `mailto: ${configData.FOOTER.EMAIL_2}`;
  const logoLink = configData.FOOTER.ICON_1;
  return (
    <div className={styles.container}>
      <div className={styles.footer}>
          &nbsp;
        <a href={email1} className={styles.mail_link}>
          
        </a>
        &nbsp;     &nbsp;
        <a href={email2} className={styles.mail_link}>
          
        </a>
        &nbsp; <br /> powered by
        <a
          href={logoLink}
          className={styles.logo_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={coloureeLogo}
            className={styles.logo_icon}
            alt="Colouree logo"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
