import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import * as colorRendererCreator from "@arcgis/core/smartMapping/renderers/color";

import {
  COLOR_COMUNI_BOUNDARIES,
  OUTLINE_COLOR_COMUNI_BOUNDARIES,
  COLOR_ASL_BOUNDARIES,
  OUTLINE_COLOR_ASL_BOUNDARIES,
} from "./constants";

type PopupObject = {
  title: string;
  collapseEnabled :boolean;
  content: any;
};

const createPopupTemplate = (
  title: string,
  content: any = null,
  collapseEnabled   : boolean
  ): PopupObject => {
  return {
    title,
    content,
    collapseEnabled
  };
};


const styleComuniBoundaries = (
  filledColor: string,
  outlineColor: string
): SimpleRenderer => {
  return new SimpleRenderer({
    symbol: new SimpleFillSymbol({
      color: filledColor,
      outline: {
        color: outlineColor,
        width: 1,
      },
    }),
  });
};

const styleASLBoundaries = (
  filledColor: string,
  outlineColor: string
): UniqueValueRenderer => {
  return new UniqueValueRenderer({
    valueExpression: "When(false, 'active', 'inactive')", // "When($feature.id == 4,'active', 'inactive')",
    uniqueValueInfos: [
      {
        value: "active",
        symbol: new SimpleFillSymbol({
          color: filledColor,
          outline: {
            color: outlineColor,
            width: 1,
          },
        }),
        label: "active",
      },
      {
        value: "inactive",
        symbol: new SimpleFillSymbol({
          color: "rgba(218, 223, 225,0.6)",
          outline: {
            color: "rgba(108, 122, 137,0.5)",
            width: 1,
          },
        }),
        label: "inactive",
      },
    ],
  });
};

const createBoundariesLayer = (
  copyright: string,
  renderer: SimpleRenderer | UniqueValueRenderer,
  popupTempl: PopupObject
): GeoJSONLayer => {
  return new GeoJSONLayer({
    copyright,
    renderer: renderer,
    popupTemplate: popupTempl,
  });
};


const comuniPopupTemplate = createPopupTemplate("{nome}", null, true); //andrà aggiustato(forse: null, true li ho messi per non dare errore)
const aslPopupTemplate = createPopupTemplate(`{nome}`, null, true);

const comuniLayer = createBoundariesLayer(
  "comuni",
  styleComuniBoundaries(
    COLOR_COMUNI_BOUNDARIES,
    OUTLINE_COLOR_COMUNI_BOUNDARIES
  ),
  comuniPopupTemplate
);
const aslLayer = createBoundariesLayer(
  "asl",
  styleASLBoundaries(COLOR_ASL_BOUNDARIES, OUTLINE_COLOR_ASL_BOUNDARIES),
  aslPopupTemplate
);

export const boundaries = {
  comuni: {
    layer: comuniLayer,
  },
  asl: {
    layer: aslLayer,
  }
};
