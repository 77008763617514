import { createContext, useCallback, useEffect, useState } from "react";

let logoutTimer: number;

type AuthContextObj = {
  token: string;
  isLoggedIn: boolean;
  login: (token: string, expirationTime: string, username:string, usernameId:string) => void;
  logout: () => void;
};

const AuthContext = createContext({
  token: '',
  isLoggedIn: false,
  login: (token: string, expirationTime: string, username:string, usernameId:string) => { },
  logout: () => { }
});

const calculateRemainingTime = (expirationTime: string): number => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedExpirationDate = localStorage.getItem('expirationTime')!;
  const storedUsername = localStorage.getItem('username');
  const storedUsernameId = localStorage.getItem('usernameId');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 0) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('username');
    localStorage.removeItem('usernameId');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    username: storedUsername,
    usernameId: storedUsernameId
  };
};

export const AuthContextProvider: React.FC = (props) => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [token, setToken] = useState(initialToken);

  const userIsLogged = !!token; // we check whether token is a truthy value

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('username');
    localStorage.removeItem('usernameId');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token: string, expirationTime: string, username:string, usernameId:string) => {
    setToken(token);
    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('username', username);
    localStorage.setItem('usernameId', usernameId);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = window.setTimeout(logoutHandler, remainingTime);

  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = window.setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue: AuthContextObj = {
    token: token!,
    isLoggedIn: userIsLogged,
    login: loginHandler,
    logout: logoutHandler
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
};

export default AuthContext;