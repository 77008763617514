import { useContext, useState } from 'react';
import { Link, NavLink,useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard'; // importa l'icona DashboardIcon
import Typography from '@mui/material/Typography';
import MapIcon from '@mui/icons-material/Map';
import Logo from './Logo/Logo';
import PasswordDialog from '../Profile/PasswordDialog';
import styles from './MainNavigation.module.css';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(255,255,255)',
    }
  },
});

const StyledButton = styled(Button)(({theme}) => ({
  color: 'white',
  marginRight: '1em', // aumenta il margine a destra del pulsante di login
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}));


const MainNavigation: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = ():void => {
    authCtx.logout();
    setAnchorEl(null);
    navigate('/auth',{replace:true});
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenPasswordDialog = () => {
    setAnchorEl(null);
    setOpenPasswordDialog(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <header className={styles.header}>
        <NavLink to='/' className={styles['push-left']}>
          <Logo />
        </NavLink>
        <nav className={styles["nav-list"]}>

        {/* Pulsante della dashboard */}
        {isLoggedIn && (
          <Button
            component={Link}
            to={location.pathname === '/' ? '/dashboard' : '/'}
            variant="contained"
            sx={{
              color: 'white',
              bgcolor: 'transparent',
              height: '40px',
              marginRight: '2em',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
            }}
          >
            {location.pathname === '/' ? (
              <>
                <DashboardIcon sx={{ color: 'white', marginRight: '0.5em' }} />
                <Typography variant="subtitle1" sx={{ textTransform: 'capitalize', color: 'white' }}>
                  Dashboard
                </Typography>
              </>
            ) : (
              <>
              <MapIcon sx={{ color: 'white', marginRight: '0.5em' }} />
              <Typography variant="subtitle1" sx={{ textTransform: 'capitalize', color: 'white' }}>
                {capitalizeFirstLetter('torna')} alla {`mappa`.toLowerCase()}
              </Typography>
              </>
            )}
          </Button>
        )}
        {/* {isLoggedIn && (
          <Button
            component={Link}
            to="/dashboard"
            variant="contained"
            sx={{
              color: 'white',
              bgcolor: 'transparent',
              height: '40px', // aggiungi questa riga per allineare verticalmente i pulsanti
              marginRight: '2em', // aumenta il margine a destra del pulsante di login
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)',
              },
            }}
          >
            <DashboardIcon sx={{ color: 'white', marginRight: '0.5em' }} />
            <Typography variant="subtitle1" sx={{ textTransform: 'capitalize', color: 'white' }}>
              Dashboard
            </Typography>
          </Button>
        )} */}

        
        {!isLoggedIn && <NavLink to='/auth' className={styles['nav-link']}><Button variant="contained" color="primary" sx={{color: 'white',
                  fontSize: '0.85rem',
                  bgcolor: 'transparent',
                  height: '40px', // aggiungi questa riga per allineare verticalmente i pulsanti
                  marginRight: '1em', // aumenta il margine a destra del pulsante di login
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                  },}}>Login</Button></NavLink> }

        {isLoggedIn && <div>
          <ThemeProvider theme={theme}>
              <Button
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
                variant="contained"
                sx={{
                  color: 'white',
                  fontSize: '1.3rem',
                  bgcolor: 'transparent',
                  height: '40px', // aggiungi questa riga per allineare verticalmente i pulsanti
                  marginRight: '1em', // aumenta il margine a destra del pulsante di login
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                  },
                }}
              >
                <AccountCircle sx={{ color: 'white', marginRight: '0.5em' }} />
                <Typography variant="subtitle1" sx={{ textTransform: 'capitalize', color: 'white' }}>
                  {localStorage.getItem('username')}
                </Typography>
              </Button>
          </ThemeProvider>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                 <MenuItem onClick={handleOpenPasswordDialog}>Change password</MenuItem>
                 {openPasswordDialog && <PasswordDialog openPasswordDialog={openPasswordDialog} handleClose={handleClosePasswordDialog}/>}
                 <MenuItem onClick={logoutHandler}>Logout</MenuItem>
              </Menu>
            </div>}
        </nav>
      </header>
    </>
  );
};

export default MainNavigation;
