import React from 'react';

import styles from './Logo.module.css';
import logoImg from '../../../data/logo/ICareMapLogoMobile.svg';

const Logo: React.FC = () => {
    return (
        <img
            className={styles.logo_img}
            src={logoImg}
            alt="IC CareMap logo"
        />
    );
};

export default Logo;
