import Dashboard from "../components/Dashboard/Dashboard";
import LeftSidebar from "../components/Dashboard/LeftSidebar";
import RightSidebar from "../components/Dashboard/RightSidebar";
import Footer from "../UI/Footer";
import ToolSidebarContextProvider from "../store/toolsidebar-context";
import MainNavigation from "../components/Layout/MainNavigation";
import styles from "./DashboardPage.module.css";

const DashboardPage: React.FC = () => {
  return (
      <ToolSidebarContextProvider>
        <MainNavigation />
        <LeftSidebar />
        <RightSidebar />
        <h1 className={styles.dashboardTitle}>Dashboard</h1>
        <Dashboard />
        <Footer />
      </ToolSidebarContextProvider>
  );
};

export default DashboardPage;

//export {}