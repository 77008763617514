import React, { PureComponent, useState, useEffect, useRef } from "react";
import styles from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import axios from 'axios';
import configData from "../../config.json";
// import { Tooltip as MuiTooltip } from '@mui/material';
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell, XAxis, YAxis, CartesianGrid,
  PieChart,
  Pie,
  Legend,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { BsFileBarGraph, BsPersonDown} from 'react-icons/bs';
import html2canvas from 'html2canvas';
import { CSVLink } from 'react-csv';
import { RxTable } from 'react-icons/rx';
import { BsInfoCircle } from 'react-icons/bs';
// import { Popup } from '@fluentui/react';
import 'react-circular-progressbar/dist/styles.css';
import Modal from 'react-modal';

//questo sotto è un esempio ma bisognerebbe fare le chiamate precise per le funzioni (va creata l'API sul server)
//IDEA: rendere la chiamata variabilizzata così da non scrivere 18 chiamate diverse ma solo una

//queste funzioni erano per recuperare i dati dal database da riattivare per la produzione finale
// function fetchPazientiChartData() {
//   return axios.get(`${configData.SERVER_URL}/stats/pazientiDashboard`).then((response) => response.data);
// }

// function fetchDiabeticiChartData() {
//   return axios.get(`${configData.SERVER_URL}/stats/diabeticiDashboard`).then((response) => response.data);
// }

// function fetchEmicraniciChartData() {
//   return axios.get(`${configData.SERVER_URL}/stats/emicraniciDashboard`).then((response) => response.data);
// }

// function fetchPoliterapiciChartData() {
//   return axios.get(`${configData.SERVER_URL}/stats/politerapiciDashboard`).then((response) => response.data);
// }

// interface MyChartProps {
//   dataKey: string;
//   dataKey2: string;
//   fetchFunction: () => Promise<any>;
// }

// interface MyChartState {
//   chartData: any[]; // o un array tipizzato con l'interfaccia dei dati dei tuoi grafici
// }

// class MyChart extends React.PureComponent<MyChartProps, MyChartState>{
//   constructor(props: MyChartProps) {
//     super(props);
//     this.state = {
//       chartData: [],
//     };
//   }

//   componentDidMount() {
//     this.props.fetchFunction().then((data) => {
//       this.setState({
//         chartData: data,
//       });
//     });
//   }

//   render() {
//     const { chartData } = this.state;
//     const { dataKey, dataKey2} = this.props;

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//           <BarChart data={chartData}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="name" />
//             <YAxis />
//             <RechartsTooltip />
//             <Legend />
//             <Bar dataKey={dataKey} fill="#8884d8" />
//             <Bar dataKey={dataKey2} fill="#82ca9d" />
//           </BarChart>
//         </ResponsiveContainer>
//     );
//   }
//}

const PercentualPoliterapici = [
  { mese: "Gennaio", tasso_politerapici: 15.72 , anno: 2022},
  { mese: "Febbraio", tasso_politerapici: 14.66 , anno: 2022 },
  { mese: "Marzo", tasso_politerapici: 15.84 , anno: 2022 },
  { mese: "Aprile", tasso_politerapici: 14.87 , anno: 2022},
  { mese: "Maggio", tasso_politerapici: 16.14 , anno: 2022 },
  { mese: "Giugno", tasso_politerapici: 14.22 , anno: 2022},
];

const PercentualRicovDiabete50 = [
  { mese: "Luglio", tasso_ricoveri_50_or_more: 0.000048 , anno: 2021},
  { mese: "Agosto", tasso_ricoveri_50_or_more: 0.000072 , anno: 2021},
  { mese: "Settembre", tasso_ricoveri_50_or_more: 0.000048 , anno: 2021},
  { mese: "Ottobre", tasso_ricoveri_50_or_more: 0.000108 , anno: 2021},
  { mese: "Novembre", tasso_ricoveri_50_or_more: 0.000084 , anno: 2021},
  { mese: "Dicembre", tasso_ricoveri_50_or_more: 0 , anno: 2021},
  { mese: "Gennaio", tasso_ricoveri_50_or_more: 0.000012 , anno: 2022},
  { mese: "Febbraio", tasso_ricoveri_50_or_more: 0.000024 , anno: 2022},
  { mese: "Marzo", tasso_ricoveri_50_or_more: 0.000036 , anno: 2022},
  { mese: "Aprile", tasso_ricoveri_50_or_more: 0.000036 , anno: 2022},
  { mese: "Maggio", tasso_ricoveri_50_or_more: 0 , anno: 2022},
  { mese: "Giugno", tasso_ricoveri_50_or_more: 0.000012 , anno: 2022},
];

const pazientiTotali = [
  { fascia_eta: "0-17", femmine: 9755, maschi: 10196, totale: 19951 },
  { fascia_eta: "18-49", femmine: 25009, maschi: 25758, totale: 50767 },
  { fascia_eta: "50-64", femmine: 18478, maschi: 17682, totale: 36160 },
  { fascia_eta: "65-74", femmine: 10316, maschi: 9255, totale: 19571 },
  { fascia_eta: "75+", femmine: 14923, maschi: 9649, totale: 24572 },
];

const diabeticiTotali = [
  { fascia_eta: "0-17", femmine: 13, maschi: 11, totale: 24 },
  { fascia_eta: "18-49", femmine: 206, maschi: 221, totale: 427 },
  { fascia_eta: "50-64", femmine: 604, maschi: 913, totale: 1517 },
  { fascia_eta: "65-74", femmine: 886, maschi: 1165, totale: 2051 },
  { fascia_eta: "75+", femmine: 1962, maschi: 1688, totale: 3650 },
];

const emicraniciTotali = [
  { fascia_eta: "0-17", femmine: 4, maschi: 0, totale: 4 },
  { fascia_eta: "18-49", femmine: 218, maschi: 44, totale: 262 },
  { fascia_eta: "50-64", femmine: 214, maschi: 35, totale: 249 },
  { fascia_eta: "65-74", femmine: 54, maschi: 20, totale: 74 },
  { fascia_eta: "75+", femmine: 27, maschi: 9, totale: 36 },
];

const politerapiciTotali = [
  { fascia_eta: "0-17", femmine: 0, maschi: 3, totale: 3 },
  { fascia_eta: "18-49", femmine: 34, maschi: 23, totale: 57 },
  { fascia_eta: "50-64", femmine: 124, maschi: 124, totale: 248 },
  { fascia_eta: "65-74", femmine: 290, maschi: 357, totale: 647 },
  { fascia_eta: "75+", femmine: 1004, maschi: 774, totale: 1778 },
];

// const DBT01a = [
//   { name: "0-17", femmine: 0, maschi: 0, amt: 2400 },
// ];

// const DBT01b = [
//   { name: "0-17", femmine: 0, maschi: 0, amt: 2400 },
// ];

// const DBT02 = [
//   { name: "0-17", femmine: 0, maschi: 0, amt: 2400 },
// ];

// // creo una funzione di fetch fittizia che restituisce l'array pazientiTotali
// const fetchPazientiTotali = () => Promise.resolve(pazientiTotali);

// // creo una funzione di fetch fittizia che restituisce l'array diabeticiTotali
// const fetchDiabeticiTotali = () => Promise.resolve(diabeticiTotali);

// // creo una funzione di fetch fittizia che restituisce l'array emicraniciTotali
// const fetchEmicraniciTotali = () => Promise.resolve(emicraniciTotali);

// // creo una funzione di fetch fittizia che restituisce l'array politerapiciTotali
// const fetchPoliterapiciTotali = () => Promise.resolve(politerapiciTotali);

const Dashboard: React.FC = () => {
  //CODICE USATO PER RECUPERARE I DATI DA MOSTRARE SU GRAFICO
  // const [totaleDiabetici, setTotaleDiabetici] = useState("");
  // const [totaleEmicranici, setTotaleEmicranici] = useState("");
  // const [totalePoliterapici, setTotalePoliterapici] = useState("");
  // const [totalePazienti, setTotalePazienti] = useState("");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await fetch("/stats/pazientiIndicatoreDashboard/totale_pazienti");
  //     const data = await response.text();
  //     setTotalePazienti(data);
  //     const response2 = await fetch("/stats/diabeticiIndicatoreDashboard/totale_diabetici");
  //     const data2 = await response.text();
  //     setTotaleDiabetici(data);
  //     const response3 = await fetch("/stats/emicraniciIndicatoreDashboard/totale_emicranici");
  //     const data3 = await response.text();
  //     setTotaleEmicranici(data);
  //     const response4 = await fetch("/stats/politerapiciIndicatoreDashboard/totale_politerapici");
  //     const data4 = await response.text();
  //     setTotalePoliterapici(data);
      
  //   };
  //   fetchData();
  // }, []);
  // const percentages = [7.64, 5, 10, 77,  15, 0.5, 5.5]
    
  
  const handleDownload = (chartId: string) => {
    const chartElement = document.getElementById(chartId);
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement('a');

        if (chartId.includes("Time")) link.download = chartId+'(2021/2022).png';
        else link.download = chartId+'(2022/06).png';

        link.href = canvas.toDataURL();
        link.click();
      });
    } else {
      console.error(`Element with id '${chartId}' not found`);
    }
  };

  const getCSVFilename = (chartName: string) => {
    if (chartName.includes("Time")) return `${chartName}-dati(2021/2022).csv`;
    else return `${chartName}-dati(2022/06).csv`;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoText, setInfoText] = useState<React.ReactNode>(null);
  const [indicatorTitle, setIndicatorTitle] = useState('');
  const [modalPosition, setModalPosition] = useState('left');

  const handleInfoClick = (infoText: React.ReactNode, title: string, side: string) => {
    setInfoText(infoText);
    setIndicatorTitle(title);
    setModalPosition(side === 'dx' ? 'right' : 'left');
    setIsModalOpen(true);
  };


  interface InfoModalProps {
    isOpen: boolean;
    onClose: () => void;
  }

  const InfoModal = ({ isOpen, onClose }: InfoModalProps) => {
    const customStyles = {
      content: {
        top: '30px',
        bottom: 'auto',
        maxWidth: '400px',
        ...(modalPosition === 'left' && {
          right: '50%',
          left: 'auto',
        }),
        ...(modalPosition === 'right' && {
          right: 'auto',
          left: '50%',
        }),
      },
    };
    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Informazioni sul tasso di politerapia">
        <div className={styles.modalContent}>
          <h2>{indicatorTitle}</h2>
          <div>{infoText}</div> 
        </div>
      </Modal>
    );
  };

  // Definisco qui gli oggetti con le informazioni degli indicatori
  const indicatorsInfo = {
    'tasso-politerapia': {
      title: 'Tasso di politerapia (75+)',
      infoText: <div> L' indicatore mostra la percentuale di pazienti con 75 anni o più che assumono nello stesso mese di riferimento più di 5 farmaci ( = 6 o più) appartenenti a distinte classi ATC4. <br></br> Il valore è calcolato con la formula: <br></br> <br></br> [ Numero di pazienti di età maggiore o uguale a 75 anni che hanno assunto farmaci appartenenti a più di 5 classi ATC4 nel mese di riferimento ] / [ Totale dei pazienti di età maggiore o uguale a 75 anni che hanno assunto almeno un farmaco nel mese di riferimento ]. <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'tasso-ricovero-diabete-50+': {
      title: 'Tasso di ricovero per diabete (50+)',
      infoText: <div> L' indicatore mostra la percentuale di pazienti con 50 anni o più che sono stati ricoverati per diabete (diagnosi principale) nel mese di riferimento. <br></br> Il valore è calcolato con la formula: <br></br><br></br> [ Numero di dimissioni ospedaliere con diagnosi principale cod. 250** per persone di età maggiore o uguale a 50 anni] / [ Totale dei pazienti di età maggiore o uguale a 50 anni ]. <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'pazienti-fascia-età': {
      title: 'Tutti i pazienti per fascia d\'età',
      infoText: <div> Il grafico mostra tutti i pazienti, in vita, residenti nei comuni coperti da ASL4, suddivisi per fascia d'età. <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'diabetici-fascia-età': {
      title: 'Pazienti diabetici per fascia d\'età',
      infoText: <div> Il grafico mostra i pazienti diabetici, in vita, residenti nei comuni coperti da ASL4, suddivisi per fascia d'età. Si identificano come diabetici i pazienti che presentano almeno una delle seguenti condizioni: prescrizione negli ultimi 12 mesi di farmaco con Codice ATC A10** (farmaceutica convenzionata); consegna negli ultimi 12 mesi di farmaco con Codice ATC A10** (farmaceutica diretta); esenzione con Codice 013.* (diabete) in corso di validità; ricevuta diagnosi principale o secondaria negli ultimi 12 mesi (si considera la data di dimissione) con Codice ICD-9_CM 250** (SDO). <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'emicranici-fascia-età': {
      title: 'Pazienti emicranici per fascia d\'età',
      infoText: <div> Il grafico mostra i pazienti emicranici, in vita, residenti nei comuni coperti da ASL4, suddivisi per fascia d'età. Si identificano come emicranici i pazienti che presentano almeno una delle seguenti condizioni: prescrizione negli ultimi 12 mesi di farmaco con Codice ATC N02C* (farmaceutica convenzionata); consegna negli ultimi 12 mesi di farmaco con Codice ATC N02C* (farmaceutica diretta); diagnosi principale o secondaria negli ultimi 12 mesi (si considera la data di dimissione) con Codice ICD-9_CM 346** (SDO). <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'politerapici-fascia-età': {
      title: 'Pazienti politerapici per fascia d\'età',
      infoText: <div> Il grafico mostra i pazienti politerapici, in vita, residenti nei comuni coperti da ASL4, suddivisi per fascia d'età. Si identificano come politerapici i pazienti che hanno ricevuto prescrizione (farmaceutica convenzionata) o consegna (farmaceutica diretta) nel mese di riferimento di più di 5 farmaci ( = 6 o più) appartenenti a distinte classi ATC4. <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'tasso-politerapia-nel-tempo': {
      title: 'Tasso di politerapia (75+): andamento',
      infoText: <div> L'indicatore mostra l'andamento nel tempo (mese per mese) della percentuale di pazienti con 75 anni o più che assumono nello stesso mese di riferimento più di 5 farmaci ( = 6 o più) appartenenti a distinte classi ATC4. <br></br> Il valore è calcolato, mese per mese, con la formula: <br></br><br></br> [ Numero di pazienti di età maggiore o uguale a 75 anni che hanno assunto farmaci appartenenti a più di 5 classi ATC4 nel mese di riferimento] / [Totale dei pazienti di età maggiore o uguale a 75 anni che hanno assunto almeno un farmaco nel mese di riferimento ]. <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'tasso-ricovero-diabete-50+-nel-tempo': {
      title: 'Tasso di ricovero per diabete (50+): andamento',
      infoText: <div> L'indicatore mostra l'andamento nel tempo (mese per mese) della percentuale di pazienti con 50 anni o più che sono stati ricoverati per diabete (diagnosi principale) nel mese di riferimento. <br></br> Il valore è calcolato, mese per mese, con la formula: <br></br><br></br> [ Numero di dimissioni ospedaliere con diagnosi principale cod. 250** per persone di età maggiore o uguale a 50 anni ] / [ Totale dei pazienti di età maggiore o uguale a 50 anni ]. <br></br><br></br> <b>FONTE:</b> database anonimizzati forniti da ASL4. <br></br> <b>ULTIMO AGGIORNAMENTO:</b> giugno 2022. </div>,
    },
    'info-funzionalità-future': {
      title: 'Download idAssistito',
      infoText: <div>  Fuzionalità disattivata </div>,
    },
  };


  return (
    <div className={styles.centerSection}>
      <div className={styles.container}>
        <div id="indicatori" className={styles.indicatorContainer}>
              <h2>Indicatori</h2>
            <div className={styles.indicatorBox}>
                <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['tasso-politerapia'].infoText, indicatorsInfo['tasso-politerapia'].title, "sx")}/>
              <div className={styles.indicator}>
                {/* <div className={styles.indicatorValue}>7.64%</div> */}
                <div className={styles.indicatorValue}>
                  <CircularProgressbar value={14.22} text={`${14.22}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di<br></br>politerapia (75+)<br></br><br></br>(2022/06)</div>
            </div>

            <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            
            {/* <div className={styles.indicatorBox}>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}>
                  <CircularProgressbar value={9} text={`${0}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di riammiss.<br></br>ospedaliera non<br></br>programmata dopo<br></br>ricov. diabete<br></br>(esclusiv. primaria)</div>
            </div>

            <div className={styles.indicatorBox}>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}><CircularProgressbar value={0} text={`${0}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di riammiss.<br></br>ospedaliera non<br></br>programmata dopo<br></br>ricov. diabete<br></br>(primaria e secondaria)</div>
            </div> */}

            <div className={styles.indicatorBox}>
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['tasso-ricovero-diabete-50+'].infoText, indicatorsInfo['tasso-ricovero-diabete-50+'].title, "dx")}/>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}><CircularProgressbar value={0} text={`${0.000012}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di ricovero<br></br>per diabete (50+)<br></br><br></br>(2022/06)</div>
            </div>

            <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            {/* <div className={styles.indicatorBox}>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}><CircularProgressbar value={0} text={`${0}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di ricov. per<br></br>amputazione (maggiore)<br></br> degli arti inferiori<br></br>in pazienti diabetici</div>
            </div> */}
            {/* <div className={styles.indicatorBox}>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}><CircularProgressbar value={0} text={`${0}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di ricov. per<br></br>amputazione (minore)<br></br>degli arti inferiori<br></br>in pazienti diabetici</div>
            </div> */}
            {/* <div className={styles.indicatorBox}>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}><CircularProgressbar value={0} text={`${0}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di soggetti trattati<br></br>con farmaci specifici<br></br>per il diabete tra <br></br>i pazienti diabetici</div>
            </div> */}
            {/* <div className={styles.indicatorBox}>
              <div className={styles.indicator}>
                <div className={styles.indicatorValue}><CircularProgressbar value={0} text={`${0}%`} styles={buildStyles({
                    textColor: "#8884D8",
                    pathColor: "#8884D8",
                    trailColor: "#eee",})}/>
                </div>
              </div>
              <div className={styles.indicatorLabel}>Tasso di esami clinici<br></br>tra i pazienti diabetici<br></br>(esame della creatina)</div>
            </div> */}
          </div>
        </div>
        <div id = "statsPazienti" className={styles.chartContainer}>
          <h2 className={styles.title}>Statistiche pazienti</h2>
          <div id = "PazientiAgeGroupChart" className={styles.chart}>
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['pazienti-fascia-età'].infoText, indicatorsInfo['pazienti-fascia-età'].title, "sx")}/>
            <h2 className={styles.subtitle}>Tutti i pazienti per fascia d'età</h2>
              <div className={styles.containerDownload}>
                <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} cursor = "pointer"  onClick={() => handleInfoClick(indicatorsInfo['info-funzionalità-future'].infoText, indicatorsInfo['info-funzionalità-future'].title, "sx")}/>
                <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('PazientiAgeGroupChart')} />
                <CSVLink data={pazientiTotali} filename={getCSVFilename("PazientiAgeGroupChart")}>
                    <RxTable color="#84817a" style={{ margin: "5px" }}  />
                </CSVLink>
              </div>
            {/* <MyChart dataKey="femmine" dataKey2="maschi" fetchFunction={fetchPazientiTotali}/> */}
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={pazientiTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -15,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fascia_eta" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div id = "DiabeticiAgeGroupChart" className={styles.chart}>
            
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['diabetici-fascia-età'].infoText, indicatorsInfo['diabetici-fascia-età'].title, "dx")}/>
            
            <h2 className={styles.subtitle}>Diabetici per fascia d'età</h2>
              <div className={styles.containerDownload}>
                  <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['info-funzionalità-future'].infoText, indicatorsInfo['info-funzionalità-future'].title, "dx")}/>
                  <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('DiabeticiAgeGroupChart')}/>
                  <CSVLink data={diabeticiTotali} filename={getCSVFilename("DiabeticiAgeGroupChart")}>
                      <RxTable color="#84817a" style={{ margin: "5px" }}  />
                  </CSVLink>
                </div>
            {/* <MyChart dataKey="femmine" dataKey2="maschi" fetchFunction={fetchDiabeticiTotali}/> */}
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={diabeticiTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -15,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fascia_eta" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div id = "EmicraniciAgeGroupChart" className={styles.chart}>
            
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['emicranici-fascia-età'].infoText, indicatorsInfo['emicranici-fascia-età'].title, "sx")}/>
            
            <h2 className={styles.subtitle}>Emicranici per fascia d'età</h2>
              <div className={styles.containerDownload}>
                  <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['info-funzionalità-future'].infoText, indicatorsInfo['info-funzionalità-future'].title, "sx")}/>
                  <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('EmicraniciAgeGroupChart')}/>
                  <CSVLink data={emicraniciTotali}filename={getCSVFilename("EmicraniciAgeGroupChart")}>
                      <RxTable color="#84817a" style={{ margin: "5px" }}  />
                  </CSVLink>
                </div>
            {/* <MyChart dataKey="femmine" dataKey2="maschi" fetchFunction={fetchEmicraniciTotali}/> */}
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={emicraniciTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -20,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fascia_eta" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div id = "PoliterapiciAgeGroupChart" className={styles.chart}>
            
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['politerapici-fascia-età'].infoText, indicatorsInfo['politerapici-fascia-età'].title, "dx")}/>
            
            <h2 className={styles.subtitle}>Politerapici per fascia d'età</h2>
              <div className={styles.containerDownload}>
                <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['info-funzionalità-future'].infoText, indicatorsInfo['info-funzionalità-future'].title, "dx")}/>
                <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('PoliterapiciAgeGroupChart')}/>
                <CSVLink data={politerapiciTotali} filename={getCSVFilename("PoliterapiciAgeGroupChart")}>
                    <RxTable color="#84817a" style={{ margin: "5px" }}  />
                </CSVLink>
              </div>
            {/* <MyChart dataKey="femmine" dataKey2="maschi" fetchFunction={fetchPoliterapiciTotali}/> */}
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={politerapiciTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -20,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fascia_eta" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* <div id = "indicatoriPerFasce" className={styles.chartContainer}>
          <h2 className={styles.title}>Indicatori per fasce d'età</h2>
          <div className={styles.chart}>
            <h2 className={styles.subtitle}>% Pazienti politerapici </h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={emicraniciTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -20,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.chart}>
            <h3 className={styles.subtitle}>% Riammiss. ospedaliera non programmata per diabete(primaria e secondaria)</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={emicraniciTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -20,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.chart}>
            <h3 className={styles.subtitle}>% Riammiss. ospedaliera non programmata per diabete(esclusiv. primaria)</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={emicraniciTotali} margin={{
                  top: 5,
                  right: 10,
                  left: -20,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:13}}/>
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="femmine" fill="#8884d8" />
                <Bar dataKey="maschi" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div> */}
        <div id = "indicatoriNelTempo" className={styles.chartContainer}>
          <h2 className={styles.title}>Andamento nel tempo</h2>
          <div id = "%PoliterapiciTimeSeriesChart" className={styles.chartInTime}>
            
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['tasso-politerapia-nel-tempo'].infoText, indicatorsInfo['tasso-politerapia-nel-tempo'].title, "sx")}/>
            
            <h2 className={styles.subtitle}>% Pazienti politerapici 2022</h2>
              <div className={styles.containerDownload}>
                {/* 
                  <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} />
                  */}
                <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('%PoliterapiciTimeSeriesChart')}/>
                <CSVLink data={PercentualPoliterapici} filename={getCSVFilename("%PoliterapiciTimeSeriesChart")}>
                    <RxTable color="#84817a" style={{ margin: "5px" }}  />
                </CSVLink>
              </div>
            <ResponsiveContainer width="100%" height={450}>
              <LineChart
                // width={500}
                height={300}
                data={PercentualPoliterapici}
                margin={{
                  top: 5,
                  right: 30,
                  left: -20,
                  bottom: 70,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mese" tick={{fontSize: 9}}/>
                <YAxis tick={{fontSize: 11}}/>
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="tasso_politerapici" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div id="%RicOspe50orMoreTimeSeriesChart" className={styles.chartInTime}>
              <BsInfoCircle color="#84817a" style={{ margin: "5px", marginRight: "10px", marginTop: "10px", float: "right" }} cursor = "pointer" onClick={() => handleInfoClick(indicatorsInfo['tasso-ricovero-diabete-50+-nel-tempo'].infoText, indicatorsInfo['tasso-ricovero-diabete-50+-nel-tempo'].title, "sx")}/>
            <h2 className={styles.subtitle}>Ricovero ospedaliero per diabete 50+ 2021/2022</h2>
              <div className={styles.containerDownload}>
                {/* 
                  <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} />
                  */}
                <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('%RicOspe50orMoreTimeSeriesChart')} />
                <CSVLink data={PercentualRicovDiabete50} filename={getCSVFilename("%RicOspe50orMoreTimeSeriesChart")}>
                    <RxTable color="#84817a" style={{ margin: "5px" }}  />
                </CSVLink>
              </div>
            <ResponsiveContainer width="100%" height={450}>
              <LineChart
                // width={500}
                height={300}
                data={PercentualRicovDiabete50}
                margin={{
                  top: 5,
                  right: 30,
                  left: -20,
                  bottom: 70,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mese" tick={{fontSize: 9}}/>
                <YAxis tick={{fontSize: 11}}/>
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="tasso_ricoveri_50_or_more" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      
      
  );
};

export default Dashboard;