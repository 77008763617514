import { BrowserRouter, Route, Routes, RouteProps} from 'react-router-dom';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { AuthContextProvider } from './store/auth-context';
import './index.css';
import App from './App';
import DashboardPage from './pages/DashboardPage';

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} /> 
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    </BrowserRouter>
  </AuthContextProvider>
);
//prima era: <Route path="/" element={<App />} /> 



// const container = document.getElementById('root')
// const root = createRoot(container)
// root.render(
//   <AuthContextProvider>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </AuthContextProvider>
// );

/*import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { AuthContextProvider } from './store/auth-context';
import './index.css';
import App from './App';

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthContextProvider>
);*/


//import { BrowserRouter, Route, RouteProps, Switch} from 'react-router-dom';
/*root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <App />
        </Route>
        <Route path="/dashboard">
          <DashboardPage />
        </Route>
      </Switch>
    </BrowserRouter>
  </AuthContextProvider>
);*/
