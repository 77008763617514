import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import FieldInfo from "@arcgis/core/popup/FieldInfo";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import FeatureReductionCluster from "@arcgis/core/layers/support/FeatureReductionCluster";
import {
  COLOR_HOSPITALSANDCLINICS,
  OUTLINE_COLOR_HOSPITALSANDCLINICS,
  COLOR_PHARMACIES,
  OUTLINE_COLOR_PHARMACIES,
  COLOR_MEDICAL_OFFICES,
  OUTLINE_COLOR_MEDICAL_OFFICES,
  COLOR_DIAGNOSTICS_FACILITIES,
  OUTLINE_COLOR_DIAGNOSTICS_FACILITIES,
  COLOR_HEALTH_FACILITIES,
  OUTLINE_COLOR_HEALTH_FACILITIES,
  COLOR_LABORATORIES,
  OUTLINE_COLOR_LABORATORIES,
  COLOR_CONSULTANTS,
  OUTLINE_COLOR_CONSULTANTS,
} from "./constants";

type PopupPointObject = {
  title: string;
  content: string;
  fieldInfos: FieldInfo[];
};

type PopupClusterObject = {
  title: string;
  content: string;
  fieldInfos: [
    {
      fieldName: string;
      format: {
        places: number;
        digitSeparator: boolean;
      };
    }
  ];
};

const fieldInfoTimeTemplate = new FieldInfo({
  fieldName: "time",
  format: {
    dateFormat: "short-date-short-time",
  },
});

const createPopupPointTemplate = (
  title: string,
  content: string,
  fieldInfos: FieldInfo
): PopupPointObject => {
  return {
    title,
    content,
    fieldInfos: [fieldInfos],
  };
};

const popupPointHospitalsAndClinicsTempl = createPopupPointTemplate(
  "Hospitals and Clinics information",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);
const popupPointPharmacyTempl = createPopupPointTemplate(
  "Pharmacies",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);
const popupPointMedicalOfficesTempl = createPopupPointTemplate(
  "Medical Offices",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);

const popupPointDiagnosticsFacilitiesTempl = createPopupPointTemplate(
  "Diagnostics Facilities",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);

const popupPointHealthFacilitiesTempl = createPopupPointTemplate(
  "Health Facilities",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);

const popupPointLaboratoriesTempl = createPopupPointTemplate(
  "Laboratories",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);

const popupPointConsultantsTempl = createPopupPointTemplate(
  "Consultants",
  `<b>Name:</b> {nome}`,
  fieldInfoTimeTemplate
);

const createPopupClusterTemplate = (
  title: string,
  content: string
): PopupClusterObject => {
  return {
    title,
    content,
    fieldInfos: [
      {
        fieldName: "cluster_count",
        format: {
          places: 0,
          digitSeparator: true,
        },
      },
    ],
  };
};

const popupClusterHospitalsAndClinicsTemplate = createPopupClusterTemplate(
  "Hospitals and Clinics",
  "You've selected {cluster_count} hospitals"
);
const popupClusterPharmaciesTemplate = createPopupClusterTemplate(
  "Farmacies",
  "You've selected {cluster_count} pharmacies"
);
const popupClusterMedicalOfficesTemplate = createPopupClusterTemplate(
  "Medical Offices",
  "You've selected {cluster_count} medical offices"
);

const popupClusterDiagnosticsFacilitiesTemplate = createPopupClusterTemplate(
  "Diagnostics Facilities",
  "You've selected {cluster_count} diagnostics facilities"
);

const popupClusterHealthFacilitiesTemplate = createPopupClusterTemplate(
  "Health Facilities",
  "You've selected {cluster_count} health facilities"
);

const popupClusterLaboratoriesTemplate = createPopupClusterTemplate(
  "Laboratories",
  "You've selected {cluster_count} laboratories"
);

const popupClusterConsultantsTemplate = createPopupClusterTemplate(
  "Consultants",
  "You've selected {cluster_count} consultants"
);

const createClusterConfig = (
  popupClusterTempl: PopupClusterObject,
  expression: string
): FeatureReductionCluster => {
  return new FeatureReductionCluster({
    //type: 'cluster',
    clusterRadius: 80,
    popupTemplate: popupClusterTempl,
    clusterMinSize: 14,
    clusterMaxSize: 40,
    labelingInfo: [
      {
        deconflictionStrategy: "none",
        labelExpressionInfo: {
          expression,
        },
        symbol: {
          type: "text",
          color: "#004a5d",
          font: {
            weight: "bold",
            family: "Noto Sans",
            size: "10px",
          },
        },
        labelPlacement: "center-center",
      },
    ],
  });
};
const clusterHospitalsAndClinicsConfig = createClusterConfig(
  popupClusterHospitalsAndClinicsTemplate,
  "Text($feature.cluster_count, '#,###')"
);
const clusterPharmaciesConfig = createClusterConfig(
  popupClusterPharmaciesTemplate,
  "Text($feature.cluster_count, '#,###')"
);

const clusterMedicalOfficesConfig = createClusterConfig(
  popupClusterMedicalOfficesTemplate,
  "Text($feature.cluster_count, '#,###')"
);

const clusterDiagnosticsFacilitiesConfig = createClusterConfig(
  popupClusterDiagnosticsFacilitiesTemplate,
  "Text($feature.cluster_count, '#,###')"
);

const clusterHealthFacilitiesConfig = createClusterConfig(
  popupClusterHealthFacilitiesTemplate,
  "Text($feature.cluster_count, '#,###')"
);

const clusterLaboratoriesConfig = createClusterConfig(
  popupClusterLaboratoriesTemplate,
  "Text($feature.cluster_count, '#,###')"
);

const clusterConsultantsConfig = createClusterConfig(
  popupClusterConsultantsTemplate,
  "Text($feature.cluster_count, '#,###')"
);

const styleMarker = (
  filledColor: string,
  outlineColor: string
): SimpleRenderer => {
  return new SimpleRenderer({
    symbol: new SimpleMarkerSymbol({
      color: filledColor,
      outline: {
        color: outlineColor,
        width: 2,
      },
      size: 5,
    }),
  });
};
const rendererHospitals = styleMarker(
  COLOR_HOSPITALSANDCLINICS,
  OUTLINE_COLOR_HOSPITALSANDCLINICS
);
const rendererPharmacies = styleMarker(
  COLOR_PHARMACIES,
  OUTLINE_COLOR_PHARMACIES
);

const renderMedicalOffices = styleMarker(
  COLOR_MEDICAL_OFFICES, // color
  OUTLINE_COLOR_MEDICAL_OFFICES // outline color
);

const renderDiagnosticsFacilities = styleMarker(
  COLOR_DIAGNOSTICS_FACILITIES, // color
  OUTLINE_COLOR_DIAGNOSTICS_FACILITIES // outline color
);

const renderHealthFacilities = styleMarker(
  COLOR_HEALTH_FACILITIES, // color
  OUTLINE_COLOR_HEALTH_FACILITIES // outline color
);

const renderLaboratories = styleMarker(
  COLOR_LABORATORIES, // color
  OUTLINE_COLOR_LABORATORIES // outline color
);

const renderConsultants = styleMarker(
  COLOR_CONSULTANTS, // color
  OUTLINE_COLOR_CONSULTANTS // outline color
);


const createClusterLayer = (
  // title: string,
  clusterConfig: FeatureReductionCluster,
  popupPointTempl: PopupPointObject,
  clusterRenderer: SimpleRenderer,
  copyright?: string
): GeoJSONLayer => {
  return new GeoJSONLayer({
    // title,
    copyright,
    featureReduction: clusterConfig,
    // popupTemplates can still be viewed on
    // individual features
    popupTemplate: popupPointTempl,
    renderer: clusterRenderer,
  });
};

export const clusters = {
  hospitalsAndClinics: {
    layer: createClusterLayer(
      clusterHospitalsAndClinicsConfig,
      popupPointHospitalsAndClinicsTempl,
      rendererHospitals
    ),
  },
  pharmacies: {
    layer: createClusterLayer(
      clusterPharmaciesConfig,
      popupPointPharmacyTempl,
      rendererPharmacies
    ),
  },
  medicalOffices: {
    layer: createClusterLayer(
      clusterMedicalOfficesConfig,
      popupPointMedicalOfficesTempl,
      renderMedicalOffices
    ),
  },
  diagnosticsFacilities: {
    layer: createClusterLayer(
      clusterDiagnosticsFacilitiesConfig,
      popupPointDiagnosticsFacilitiesTempl,
      renderDiagnosticsFacilities
    ),
  },
  healthFacilities: {
    layer: createClusterLayer(
      clusterHealthFacilitiesConfig,
      popupPointHealthFacilitiesTempl,
      renderHealthFacilities
    ),
  },
  laboratories: {
    layer: createClusterLayer(
      clusterLaboratoriesConfig,
      popupPointLaboratoriesTempl,
      renderLaboratories
    ),
  },
  consultants: {
    layer: createClusterLayer(
      clusterConsultantsConfig,
      popupPointConsultantsTempl,
      renderConsultants
    ),
  },
};
