import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import { ToolSidebarContext } from '../../../../store/toolsidebar-context';

const MaterialUISwitch = styled((Switch))(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
            transform: "translateX(50px)",
            "& + .MuiSwitch-track": {
                backgroundColor: '#6b7d8e',
                opacity:1
                },
            ".MuiSwitch-thumb":{
                '&:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
    '#fff'
)}" d="M7.521 17.5Q6.125 17.5 5.146 16.51Q4.167 15.521 4.167 14.125V7.417Q3.438 7.125 2.969 6.49Q2.5 5.854 2.5 5.042Q2.5 3.979 3.24 3.24Q3.979 2.5 5.042 2.5Q6.104 2.5 6.844 3.24Q7.583 3.979 7.583 5.042Q7.583 5.854 7.115 6.49Q6.646 7.125 5.917 7.417V14.125Q5.917 14.792 6.385 15.271Q6.854 15.75 7.521 15.75Q8.188 15.75 8.656 15.271Q9.125 14.792 9.125 14.125V5.875Q9.125 4.479 10.104 3.49Q11.083 2.5 12.479 2.5Q13.875 2.5 14.854 3.49Q15.833 4.479 15.833 5.875V12.583Q16.562 12.875 17.031 13.51Q17.5 14.146 17.5 14.958Q17.5 16.021 16.76 16.76Q16.021 17.5 14.958 17.5Q13.896 17.5 13.156 16.76Q12.417 16.021 12.417 14.958Q12.417 14.146 12.885 13.5Q13.354 12.854 14.083 12.583V5.875Q14.083 5.208 13.615 4.729Q13.146 4.25 12.479 4.25Q11.812 4.25 11.344 4.729Q10.875 5.208 10.875 5.875V14.125Q10.875 15.521 9.896 16.51Q8.917 17.5 7.521 17.5ZM5.042 5.833Q5.375 5.833 5.604 5.604Q5.833 5.375 5.833 5.042Q5.833 4.708 5.604 4.479Q5.375 4.25 5.042 4.25Q4.708 4.25 4.479 4.479Q4.25 4.708 4.25 5.042Q4.25 5.375 4.479 5.604Q4.708 5.833 5.042 5.833ZM14.958 15.75Q15.292 15.75 15.521 15.521Q15.75 15.292 15.75 14.958Q15.75 14.625 15.521 14.396Q15.292 14.167 14.958 14.167Q14.625 14.167 14.396 14.396Q14.167 14.625 14.167 14.958Q14.167 15.292 14.396 15.521Q14.625 15.75 14.958 15.75ZM5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042ZM14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Z"/></svg>')`,
                    },
            }
        }
        },
        width: 90,
        height: 40,
        padding: 8,
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            backgroundColor: '#6b7d8e',
            opacity:1,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff'
                )}" d="M12.667 13.917 13.917 12.667 10.875 9.646V5.875H9.125V10.333ZM10 18.333Q8.271 18.333 6.75 17.677Q5.229 17.021 4.104 15.896Q2.979 14.771 2.323 13.25Q1.667 11.729 1.667 10Q1.667 8.271 2.323 6.75Q2.979 5.229 4.104 4.104Q5.229 2.979 6.75 2.323Q8.271 1.667 10 1.667Q11.729 1.667 13.25 2.323Q14.771 2.979 15.896 4.104Q17.021 5.229 17.677 6.75Q18.333 8.271 18.333 10Q18.333 11.729 17.677 13.25Q17.021 14.771 15.896 15.896Q14.771 17.021 13.25 17.677Q11.729 18.333 10 18.333ZM10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10ZM10 16.583Q12.729 16.583 14.656 14.656Q16.583 12.729 16.583 10Q16.583 7.271 14.656 5.344Q12.729 3.417 10 3.417Q7.271 3.417 5.344 5.344Q3.417 7.271 3.417 10Q3.417 12.729 5.344 14.656Q7.271 16.583 10 16.583Z"/></svg>')`,
                    left: 12,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
    '#fff'
)}" d="M7.521 17.5Q6.125 17.5 5.146 16.51Q4.167 15.521 4.167 14.125V7.417Q3.438 7.125 2.969 6.49Q2.5 5.854 2.5 5.042Q2.5 3.979 3.24 3.24Q3.979 2.5 5.042 2.5Q6.104 2.5 6.844 3.24Q7.583 3.979 7.583 5.042Q7.583 5.854 7.115 6.49Q6.646 7.125 5.917 7.417V14.125Q5.917 14.792 6.385 15.271Q6.854 15.75 7.521 15.75Q8.188 15.75 8.656 15.271Q9.125 14.792 9.125 14.125V5.875Q9.125 4.479 10.104 3.49Q11.083 2.5 12.479 2.5Q13.875 2.5 14.854 3.49Q15.833 4.479 15.833 5.875V12.583Q16.562 12.875 17.031 13.51Q17.5 14.146 17.5 14.958Q17.5 16.021 16.76 16.76Q16.021 17.5 14.958 17.5Q13.896 17.5 13.156 16.76Q12.417 16.021 12.417 14.958Q12.417 14.146 12.885 13.5Q13.354 12.854 14.083 12.583V5.875Q14.083 5.208 13.615 4.729Q13.146 4.25 12.479 4.25Q11.812 4.25 11.344 4.729Q10.875 5.208 10.875 5.875V14.125Q10.875 15.521 9.896 16.51Q8.917 17.5 7.521 17.5ZM5.042 5.833Q5.375 5.833 5.604 5.604Q5.833 5.375 5.833 5.042Q5.833 4.708 5.604 4.479Q5.375 4.25 5.042 4.25Q4.708 4.25 4.479 4.479Q4.25 4.708 4.25 5.042Q4.25 5.375 4.479 5.604Q4.708 5.833 5.042 5.833ZM14.958 15.75Q15.292 15.75 15.521 15.521Q15.75 15.292 15.75 14.958Q15.75 14.625 15.521 14.396Q15.292 14.167 14.958 14.167Q14.625 14.167 14.396 14.396Q14.167 14.625 14.167 14.958Q14.167 15.292 14.396 15.521Q14.625 15.75 14.958 15.75ZM5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042Q5.042 5.042 5.042 5.042ZM14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Q14.958 14.958 14.958 14.958Z"/></svg>')`,
                right:12,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
          },
        },
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            boxShadow: 'none',
            margin: -4,
            '&:before': {
                        content: "''",
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff'
                )}" d="M12.667 13.917 13.917 12.667 10.875 9.646V5.875H9.125V10.333ZM10 18.333Q8.271 18.333 6.75 17.677Q5.229 17.021 4.104 15.896Q2.979 14.771 2.323 13.25Q1.667 11.729 1.667 10Q1.667 8.271 2.323 6.75Q2.979 5.229 4.104 4.104Q5.229 2.979 6.75 2.323Q8.271 1.667 10 1.667Q11.729 1.667 13.25 2.323Q14.771 2.979 15.896 4.104Q17.021 5.229 17.677 6.75Q18.333 8.271 18.333 10Q18.333 11.729 17.677 13.25Q17.021 14.771 15.896 15.896Q14.771 17.021 13.25 17.677Q11.729 18.333 10 18.333ZM10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10Q10 10 10 10ZM10 16.583Q12.729 16.583 14.656 14.656Q16.583 12.729 16.583 10Q16.583 7.271 14.656 5.344Q12.729 3.417 10 3.417Q7.271 3.417 5.344 5.344Q3.417 7.271 3.417 10Q3.417 12.729 5.344 14.656Q7.271 16.583 10 16.583Z"/></svg>')`,
                    },
          }
}));

const SwitchDistanceTime: React.FC = () => {
    const toolSidebarCtx = useContext(ToolSidebarContext);

    return (
        <FormGroup>
            <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} />} label="" onChange={(event,checked) => toolSidebarCtx.onChangeSwitchDTHandler(checked)}/>
        </FormGroup>
    );

};

export default SwitchDistanceTime;
