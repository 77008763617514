import { useContext, useEffect, useRef } from "react";
import styles from "./MapContent.module.css";
import { ToolSidebarContext } from "../../store/toolsidebar-context";
import { handleOverlayLayers, view } from "../../utils/map";
import { WorkspaceContext } from "../../store/workspace-context";

const MapContent = () => {
  const mapRef = useRef(null);
  const toolSidebarCtx = useContext(ToolSidebarContext);
  const workspaceSidebarCtx = useContext(WorkspaceContext);

  // create map and view
  useEffect(() => {
    const loadMap = async () => {
      const { initializeMap } = await import("../../utils/map");
      await initializeMap(mapRef.current);
    };
    loadMap();

    // clean up code run when route changes
    return () => {
      view.container = null;
    };
  }, [mapRef]);

  useEffect(() => {
    const updateLayer = async () => {
      const { addBoundaries } = await import("../../utils/map");
      const { removeLayer } = await import("../../utils/map");
      handleOverlayLayers(
        toolSidebarCtx.boundaries,
        addBoundaries,
        removeLayer
      );
    };
    updateLayer();
  }, [toolSidebarCtx.boundaries]);

  useEffect(() => {
    const updateLayer = async () => {
      const { addCluster } = await import("../../utils/map");
      const { removeLayer } = await import("../../utils/map");
      handleOverlayLayers(toolSidebarCtx.poi, addCluster, removeLayer);
    };
    updateLayer();
  }, [toolSidebarCtx.poi]);

  useEffect(() => {
    const updateLayer = async () => {
      const { addDiseases } = await import("../../utils/map");
      const { removeLayer } = await import("../../utils/map");
      handleOverlayLayers(toolSidebarCtx.diseases, addDiseases, removeLayer);
    };
    updateLayer();
  }, [toolSidebarCtx.diseases]);

  useEffect(() => {
    const updateLayer = async () => {
      const { addHeatmap } = await import("../../utils/map");
      const { removeLayer } = await import("../../utils/map");
      const { showClusterLayers } = await import("../../utils/map");
      const { hideClusterLayers } = await import("../../utils/map");
      const dataUrl = toolSidebarCtx.takeHeatmapData().url;
      if (toolSidebarCtx.heatmapActive) {
        removeLayer(`Heatmap LDI`); // we remove the old heatmap if exists
        hideClusterLayers();
        addHeatmap(`Heatmap LDI`, dataUrl);
      } else {
        removeLayer(`Heatmap LDI`);
        showClusterLayers();
      }
    };
    updateLayer();
  }, [toolSidebarCtx.heatmapActive, workspaceSidebarCtx.activeWorkspace]);

  useEffect(() => {
    const updateLayer = async () => {
      const { addReachabilityPolygons } = await import("../../utils/map");
      const { removeLayer } = await import("../../utils/map");
      const layerName = toolSidebarCtx.prepareLayerName();
      if (toolSidebarCtx.showReachabilityPolygons) {
        const url = toolSidebarCtx.preparePolygonQuery();
        addReachabilityPolygons(layerName, url);
      } else {
        removeLayer(layerName);
      }
    };
    updateLayer();
  }, [toolSidebarCtx.showReachabilityPolygons]);

  return <div ref={mapRef} className={styles.map_container} />;
};

export default MapContent;
