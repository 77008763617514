import HeatmapRenderer from "@arcgis/core/renderers/HeatmapRenderer";

const colors = [
  "rgba(115, 0, 115, 0)",
  "rgba(130, 0, 130,0.7)",
  "rgba(145, 0, 145,0.7)",
  "rgba(160, 0, 160,0.7)",
  "rgba(175, 0, 175,0.7)",
  "rgba(195, 0, 195,0.7)",
  "rgba(215, 0, 215,0.7)",
  "rgba(235, 0, 235,0.7)",
  "rgba(255, 0, 255,0.7)",
  "rgba(255, 88, 160,0.7)",
  "rgba(255, 137, 107,0.7)",
  "rgba(255, 185, 53,0.7)",
  "rgba(255, 234, 0,0.7)",
];
export const rendererHeatmap = new HeatmapRenderer({
  colorStops: [
    { color: colors[0], ratio: 0 },
    { color: colors[1], ratio: 0.083 },
    { color: colors[2], ratio: 0.166 },
    { color: colors[3], ratio: 0.249 },
    { color: colors[4], ratio: 0.332 },
    { color: colors[5], ratio: 0.415 },
    { color: colors[6], ratio: 0.498 },
    { color: colors[7], ratio: 0.581 },
    { color: colors[8], ratio: 0.664 },
    { color: colors[9], ratio: 0.747 },
    { color: colors[10], ratio: 0.83 },
    { color: colors[11], ratio: 0.913 },
    { color: colors[12], ratio: 1 },
  ],
  blurRadius: 10,
  maxPixelIntensity: 40,
  minPixelIntensity: 0,
});
