import MapPage from "../components/Map/MapPage";
import Footer from "../UI/Footer";
import ToolSidebarContextProvider from "../store/toolsidebar-context";

const HomePage: React.FC = () => {
  return (
    <ToolSidebarContextProvider>
      <MapPage />
      <Footer />
    </ToolSidebarContextProvider>
  );
};

export default HomePage;
