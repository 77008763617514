import React, { useContext, Dispatch, SetStateAction } from "react";
import { useMediaQuery } from "react-responsive";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SettingsIcon from "@mui/icons-material/SettingsApplications";
import ChartIcon from "@mui/icons-material/BarChart";
import styles from "./Sidebars.module.css";

import { NavbarContext } from "../../store/navbar-context";
import { deviceSizes } from "../../responsive/deviceSizes";
import SidebarAnalyses from "./SidebarAnalysis/SidebarAnalysis";
import SidebarTools from "./SidebarTools/SidebarTools";

const SidebarContainer: React.FC<{
  mobileNavValue: number;
  setMobileNavValue: Dispatch<SetStateAction<number>>;
}> = (props) => {
  const { mobileNavValue, setMobileNavValue } = props;
  const navbarCtx = useContext(NavbarContext);
  const isMobile = useMediaQuery({ maxWidth: deviceSizes.mobile });

  const onChangeHandler = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    if (newValue === 0) {
      navbarCtx.setToolSidebarActive(true);
      navbarCtx.setAnalysisSidebarActive(false);
    } else if (newValue === 1) {
      navbarCtx.setToolSidebarActive(false);
      navbarCtx.setAnalysisSidebarActive(true);
    }
    setMobileNavValue(newValue);
  };

  return (
    <div className={styles.sidebar_mobile_wrapper}>
      <Tabs
        value={mobileNavValue}
        onChange={onChangeHandler}
        aria-label="icon tabs example"
      >
        <Tab icon={<SettingsIcon />} aria-label="settings" />
        <Tab icon={<ChartIcon />} aria-label="analysis" />
      </Tabs>

      {navbarCtx.toolSidebarActive && <SidebarTools />}
      {navbarCtx.analysisSidebarActive && <SidebarAnalyses />}
    </div>
  );
};

export default SidebarContainer;
