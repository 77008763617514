import React from 'react';
import { useContext } from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage';
import AuthContext from './store/auth-context';
import NavbarContextProvider from './store/navbar-context';

import './App.css';


const App: React.FC = () => {
    const authCtx = useContext(AuthContext);

    return (
        <NavbarContextProvider>
            <Layout>
                <Routes> 
                    <Route path='/' element={
                    authCtx.isLoggedIn ? <HomePage /> : <Navigate to='/auth' replace={true}/> 
                    }>
                    </Route>
                    {!authCtx.isLoggedIn && (
                        <Route path='/auth' element={<AuthPage />} />
                    )}
                    <Route path='*' element={<Navigate to='/' replace={true} />}>
                    </Route>
                </Routes>
            </Layout>
        </NavbarContextProvider>
    );
};
export default App;
