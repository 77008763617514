import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProfileForm from './ProfileForm';

const FormDialog:React.FC<{
  openPasswordDialog:boolean,handleClose:() => void
}> = (props) => {
  const {openPasswordDialog, handleClose} = props;
  return (
    <div >
      <Dialog open={openPasswordDialog} onClose={handleClose} >
        <DialogTitle sx={{color: 'rgb(100, 100, 100)',textAlign: 'center',marginTop: '0.5em',paddingBottom:0}}>Change Password</DialogTitle>
        <DialogContent sx={{minWidth:'400px', paddingBottom:0}} >
          <ProfileForm handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
   </div>
  );
}

export default FormDialog;
