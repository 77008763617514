import React, {useContext} from 'react';
import Slider from 'rc-slider';
import { ToolSidebarContext } from '../../../../store/toolsidebar-context';
import 'rc-slider/assets/index.css';

import styles from './CustomSlider.module.css';

const CustomSlider: React.FC = () => {
    const toolSidebatCtx = useContext(ToolSidebarContext);

    return (
        <>
            <h4 className={styles.title}>Filtro di distanza</h4>
            <Slider
                className={styles['rc-slider']}
                min={1}
                max={4}
                marks={{
                    1: { style: { marginTop: '2.5em' }, label: toolSidebatCtx.sliderValues[0] },
                    2: { style: { marginTop: '2.5em' }, label: toolSidebatCtx.sliderValues[1] },
                    3: { style: { marginTop: '2.5em' }, label: toolSidebatCtx.sliderValues[2] },
                    4: { style: { marginTop: '2.5em' }, label: toolSidebatCtx.sliderValues[3] }
                }}
                railStyle={{ padding: '5px 0' }}
                trackStyle={{ padding: '5px 0', backgroundColor: '#2386dd' }}
                handleStyle={{ width: '19px', height: '19px' }}
                dotStyle={{
                    bottom: '-7px',
                    marginLeft: '0',
                    width: '13px',
                    height: '13px',
                }}
                onChange={(value:number | number[]) => {
                    toolSidebatCtx.onChangeSliderHandler(value);
                }}
            />
        </>
    );
};

export default CustomSlider;
