import React, { useContext } from "react";

import styles from "./AnalyseType.module.css";
import "../../../../CustomStyles.css";
import SwitchDistanceTime from "./SwitchDistanceTime";
import SwitchPedestrianCar from "./SwitchPedestrianCar";
import AnalysesOptions from "./AnalysesOptions";
import Card from '../../../../UI/Card';
import { ToolSidebarContext } from "../../../../store/toolsidebar-context";

const AnalyseType: React.FC<{
  legendFontSize: string;
}> = (props) => {
  const { legendFontSize } = props;
  const toolSidebarCtx = useContext(ToolSidebarContext);

  const wrapperStyles = toolSidebarCtx.isMapExplore
      ? styles.wrapper
      : `${styles.wrapper} ${styles.wrapper_disabled}`;
  const cardStyles = toolSidebarCtx.isMapExplore ? '' : styles.card_disabled;

  return (

      <div className={wrapperStyles}>
        <div className={styles.wrapper_item}>
          <h5 className={styles.subtitle}>Analisi aree di influenza</h5>
          <AnalysesOptions legendFontSize={legendFontSize} />
          <h4 className={styles.title}>Tipo di analisi</h4>
          <div className={styles.buttons}>
            <SwitchDistanceTime />
            <SwitchPedestrianCar />
          </div>
        </div>
      </div>
  );
};

export default AnalyseType;
