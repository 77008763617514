// APP TEXT
export const APP_TITLE = "Welcome, Developer";
// PAGES TITLE
export const PAGE_TITLE_HOME = "Home";
// UI CONTSTANTS
export const FOOTER_HEIGHT = 60;

// SIDEBAR

// BASE LAYERS
export const LABEL_GREY = { inputProps: { "aria-label": "Grey" } };
export const LABEL_DARK_GREY = { inputProps: { "aria-label": "Dark grey" } };
export const LABEL_TOPOGRAPHIC = {
  inputProps: { "aria-label": "Topographic" },
};
export const LABEL_SATELLITE = { inputProps: { "aria-label": "Satellite" } };


// BOUNDARIES
export const COLOR_COMUNI_BOUNDARIES = "rgba(251, 192, 147,0.5)";
export const OUTLINE_COLOR_COMUNI_BOUNDARIES = "rgba(248,149,73,0.5)";
export const LABEL_COMUNI_BOUNDARIES = {
  inputProps: { "aria-label": "Comuni" },
};
export const COLOR_ASL_BOUNDARIES = "rgba(116,211,174,0.5)";
export const OUTLINE_COLOR_ASL_BOUNDARIES = "rgba(60,190,140,0.5)";
export const LABEL_ASL_BOUNDARIES = { inputProps: { "aria-label": "ASL" } };


// POI
export const LABEL_HOSPITALSANDCLINICS = {
  inputProps: { "aria-label": "Ospedali e cliniche" },
};
export const COLOR_HOSPITALSANDCLINICS = "#C53D64";
export const OUTLINE_COLOR_HOSPITALSANDCLINICS = "#D98F9D";


export const LABEL_PHARMACIES = { 
  inputProps: { "aria-label": "Farmacie" } 
};
export const COLOR_PHARMACIES = "#3D8B4F";
export const OUTLINE_COLOR_PHARMACIES = "#73C181";


export const LABEL_MEDICAL_OFFICES = {
  inputProps: { "aria-label": "Studi medici" },
};
export const COLOR_MEDICAL_OFFICES = "#BA5436";
export const OUTLINE_COLOR_MEDICAL_OFFICES = "#D89783";


export const LABEL_DIAGNOSTICS_FACILITIES = {
  inputProps: { "aria-label": "Strutture per attività diagnostica" },
};
export const COLOR_DIAGNOSTICS_FACILITIES = "#00907E";
export const OUTLINE_COLOR_DIAGNOSTICS_FACILITIES = "#56C7B3";


export const LABEL_HEALTH_FACILITIES = {
  inputProps: { "aria-label": "Strutture assistenziali" },
};
export const COLOR_HEALTH_FACILITIES = "#00E5DD";
export const OUTLINE_COLOR_HEALTH_FACILITIES = "#00ADA6";


export const LABEL_LABORATORIES = {
  inputProps: { "aria-label": "Laboratori" },
};
export const COLOR_LABORATORIES = "#E8E218";
export const OUTLINE_COLOR_LABORATORIES = "#AEA770";


export const LABEL_CONSULTANTS = {
  inputProps: { "aria-label": "Consultori" },
};
export const COLOR_CONSULTANTS = "#7C4C87";
export const OUTLINE_COLOR_CONSULTANTS = "#B27EBD";


// DISEASES
export const LABEL_TEST = { inputProps: { "aria-label": "Test" } };
export const LABEL_MIGRAINE = { inputProps: { "aria-label": "Emicranici" } };
export const LABEL_DIABETIC = { inputProps: { "aria-label": "Diabetici" } };
export const COLOR_MIGRAINE = "#BA5436";
export const OUTLINE_COLOR_MIGRAINE = "#D89783"; //maybe is better change this color with white color or the same of color_migraine, because when you change disease thic color appear

// REACHABILITY POLYGONS
export const COLOR_REACHABILITY_POLYGONS = "rgba(49, 77, 255,0.5)";
export const OUTLINE_REACHABILITY_POLYGONS = "#1d2e99";
