import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import styles from './ProfileForm.module.css';

const ProfileForm: React.FC<{handleClose: () => void}> = (props) => {
  const {handleClose} = props;
  const navigate = useNavigate();
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const authCtx = useContext(AuthContext);

  const submitHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const currentPassword: any = newPasswordInputRef.current;
    const enteredNewPassword: string = currentPassword.value;

    // add validation

    fetch('https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyDyEqLfvdPfQTy6GmZTVQAAnib_cWTqHco',
      {
        method: 'POST',
        body: JSON.stringify({
          idToken: authCtx.token,
          password: enteredNewPassword,
          returnSecureToken: false
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        // assumption: always succeeds!
        navigate('/');
      });
  };

  return (
    <form className={styles.form}>
      <div className={styles.control}>
        <label htmlFor='new-password'>New Password</label>
        <input type='password' id='new-password' minLength={7} ref={newPasswordInputRef} />
      </div>
      <div className={styles.action}>
        <button className={styles['cancel-btn']} onClick={handleClose}>Cancel</button>
        <button onClick={submitHandler}>Change Password</button>
      </div>
    </form>
  );
}

export default ProfileForm;
