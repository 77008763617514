import React, { useMemo, useContext, useState } from "react";
import { ToolSidebarContext } from "./toolsidebar-context";
import Layer from "esri/layers/Layer";
import { map } from "../utils/map";
import Features from "../models/myFeature";

const defaultFeatures = {
  boundaries: {
    Comuni: false,
    ASL: false,
  },
  poi: {
    "Ospedali e cliniche": false,
    Farmacie: false,
    "Studi medici": false,
    "Strutture per attività diagnostica": false,
    "Strutture assistenziali": false,
    Laboratori: false,
    "Consultori": false,
  },
  heatmap: false,
  diseases: {
    Emicrania: false,
    Diabete: false,
  },
  analysis: null,
  geometry: null,
  mapFeatures: null,
};
type WorkspaceContextObj = {
  onClickWorkspaceHandler: (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>,
    workspace: any
  ) => void;
  getWorkspaceFeatures: () => Features;
  activeWorkspace: any;
};

export const WorkspaceContext = React.createContext<WorkspaceContextObj>({
  onClickWorkspaceHandler: (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>,
    workspace: any
  ) => {},
  getWorkspaceFeatures: () => defaultFeatures,
  activeWorkspace: null,
});

const WorkspaceContextProvider: React.FC = (props) => {
  const { children } = props;
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const toolSidebarCtx = useContext(ToolSidebarContext);

  const getWorkspaceFeatures = (): Features => {
    const layers: any = map.layers;

    return {
      boundaries: {
        Comuni: toolSidebarCtx.boundaries[0].isChecked,
        ASL: toolSidebarCtx.boundaries[1].isChecked,
      },
      poi: {
        ["Ospedali e cliniche"]: toolSidebarCtx.poi[0].isChecked,
        Farmacie: toolSidebarCtx.poi[1].isChecked,
        ["Studi medici"]: toolSidebarCtx.poi[2].isChecked,
        ["Strutture per attività diagnostica"]: toolSidebarCtx.poi[3].isChecked,
        ["Strutture assistenziali"]:
          toolSidebarCtx.poi[4].isChecked,
        ["Laboratori"]: toolSidebarCtx.poi[5].isChecked,
        ["Consultori"]: toolSidebarCtx.poi[6].isChecked,
      },
      heatmap: toolSidebarCtx.heatmapActive,
      diseases: {
        Emicrania: toolSidebarCtx.diseases[0].isChecked,
        Diabete: toolSidebarCtx.diseases[1].isChecked,
      },
      analysis: {
        // setting of the filters
      },
      geometry:
        // one graphics layer which container all the graphics layer drew by user (graphics.items)
        layers.find((layer: Layer) => layer.type === "graphics").graphics.items,
      mapFeatures: {
        // map zoom, map center
        // maybe we will not need this since we initialise the map with default setting anyway
      },
    };
  };

  const handleBoundariesInWorkspace = (workspace: any) => {
    const boundariesList = workspace.wsinfo.features.boundaries;
    const activeBoundaries = [];
    for (const item in boundariesList) {
      if (boundariesList[item]) {
        activeBoundaries.push(item);
      }
    }
    toolSidebarCtx.changeActiveBoundaries(activeBoundaries, true);
  };

  const handleClusterInWorkspace = (workspace: any) => {
    const poiList = workspace.wsinfo.features.poi;
    const activePois = [];
    for (const item in poiList) {
      if (poiList[item]) {
        activePois.push(item);
      }
    }
    toolSidebarCtx.changeActivePoi(activePois, true);
  };

  const handleHeatmapInWorkspace = (workspace: any) => {
    const heatmapStatus = workspace.wsinfo.features.heatmap;
    toolSidebarCtx.onChangeHeatmapHandler(true, heatmapStatus);
  };

  const onClickWorkspaceHandler = (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>,
    workspace: any
  ) => {
    setActiveWorkspace(workspace);
    // apply all the saved features to the map and sidebars
    // prevent from firing when we click edit or delete icon

    // firstly clear the heatmap because it cannot exist before applying cluster and another heatmap
    const target = event.target as any;
    console.log(target.parentElement);
    // for now only applied when we clicked on the svg not path
    const editIconClicked =
      (target.tagName === "svg" && target.dataset.testid === "EditIcon") ||
      (target.parentElement.tagName === "svg" &&
        target.parentElement.dataset.testid === "EditIcon");
    if (
      editIconClicked ||
      (target.tagName !== "path" && target.tagName !== "svg")
    ) {
      // toolSidebarCtx.clearHeatmap();
      handleBoundariesInWorkspace(workspace);
      handleClusterInWorkspace(workspace);
      handleHeatmapInWorkspace(workspace);
    }
  };

  const contextValue: WorkspaceContextObj = useMemo(
    (): WorkspaceContextObj => ({
      onClickWorkspaceHandler,
      getWorkspaceFeatures,
      activeWorkspace,
    }),
    [activeWorkspace, getWorkspaceFeatures, onClickWorkspaceHandler]
  );

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceContextProvider;
