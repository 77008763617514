import React from 'react';
import styles from "./Dashboard.module.css";

const LeftSidebar = () => {
  const scrollToSection = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute('href');
    if (target) {
      document.querySelector(target)?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.leftSection}>
      {/* <h2>Menu rapido</h2> */}
      <nav>
        <ul>
          <button><a href="#indicatori" onClick={scrollToSection}>Indicatori</a></button><br></br>
          <button><a href="#statsPazienti" onClick={scrollToSection}>Statistiche pazienti</a></button><br></br>
          {/* <button><a href="#indicatoriPerFasce" onClick={scrollToSection}>Indicatori per fasce d'età</a></button><br></br> */}
          <button><a href="#indicatoriNelTempo" onClick={scrollToSection}>Indicatori nel tempo</a></button>
        </ul>
      </nav>
    </div>
  );
};

export default LeftSidebar;