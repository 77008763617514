import React, { useMemo, useState } from "react";
import BoundariesLayer from "../models/boundariesLayer";
import PoiLayer from "../models/poiLayer";
import DiseaseLayer from "../models/diseaseLayer";
import {
  defaultBoundaries,
  defaultPoi,
  defaultDiseases,
} from "../utils/defaultLayers";
import configData from "../config.json";

const defaultHeatmapData = { url: "", heatmapName: "" };
const defaultSliderValues = ["5min", "10min", "15min", "20min"];
export type ToolSidebarContextObject = {
  isMapExplore: boolean;
  isMapAnalyze: boolean;
  changeMapType: () => void;
  changeActiveBoundaries: (titles: string[], openWorkspace: boolean) => void;
  changeActivePoi: (titles: string[], openWorkspace: boolean) => void;
  changeActiveDiseases: (titles: string[], openWorkspace: boolean) => void;
  boundaries: BoundariesLayer[];
  poi: PoiLayer[];
  diseases: DiseaseLayer[];
  heatmapActive: boolean;
  onChangeHeatmapHandler: (openWorkspace: boolean, workspace?: any) => void;
  takeHeatmapData: () => {
    url: string | undefined;
    heatmapName: string | undefined;
  };
  clearHeatmap: () => void;
  analysesOption: string;
  setAnalysesOption: React.Dispatch<React.SetStateAction<string>>;
  onChangeSwitchDTHandler: (checked: boolean) => void;
  onChangeSwitchPCHandler: (checked: boolean) => void;
  sliderValues: string[];
  onChangeSliderHandler: (value: number | number[]) => void;
  preparePolygonQuery: () => string;
  prepareLayerName: () => string;
  setShowReachabilityPolygons: React.Dispatch<React.SetStateAction<boolean>>;
  showReachabilityPolygons: boolean;
};

export const ToolSidebarContext = React.createContext<ToolSidebarContextObject>(
  {
    isMapExplore: true,
    isMapAnalyze: false, //forse va tolto, l'ho inserito per cercare di far funzionare il pulsante CALCOLA REACHABILITY
    changeMapType: () => {},
    changeActiveBoundaries: (titles: string[], openWorkspace: boolean) => {},
    changeActivePoi: (titles: string[], openWorkspace: boolean) => {},
    changeActiveDiseases: (titles: string[], openWorkspace: boolean) => {},
    boundaries: defaultBoundaries,
    poi: defaultPoi,
    diseases: defaultDiseases,
    heatmapActive: false,
    onChangeHeatmapHandler: (openWorkspace: boolean, workspace?: any) => {},
    takeHeatmapData: () => defaultHeatmapData,
    clearHeatmap: () => {},
    analysesOption: defaultPoi[0].name,
    setAnalysesOption: (state) => {}, // noop default callback
    onChangeSwitchDTHandler: (checked: boolean) => {},
    onChangeSwitchPCHandler: (checked: boolean) => {},
    sliderValues: defaultSliderValues,
    onChangeSliderHandler: (value: number | number[]) => {},
    preparePolygonQuery: () => "",
    prepareLayerName: () => "",
    setShowReachabilityPolygons: (state) => {}, // noop default callback
    showReachabilityPolygons: false,
  }
);

const ToolSidebarContextProvider: React.FC = (props) => {
  const { children } = props;
  const [isMapExplore, setIsMapExplore] = useState(true);
  const [isMapAnalyze, setIsMapAnalyze] = useState(false);
  const [boundaries, setBoundaries] = useState(defaultBoundaries);
  const [poi, setPoi] = useState(defaultPoi);
  const [diseases, setDiseases] = useState(defaultDiseases);
  const [heatmapActive, setHeatmapActive] = useState(false);
  const [analysesOption, setAnalysesOption] = useState(defaultPoi[0].name);
  const [switchDistanceTime, setSwitchDistanceTime] = useState("time");
  const [switchPedestrianCar, setSwitchPedestrianCar] = useState("pedestrian");
  const [sliderValues, setSliderValues] = useState(defaultSliderValues);
  const [sliderCurrentValueId, setSliderCurrentValueId] = useState(0);
  const [showReachabilityPolygons, setShowReachabilityPolygons] =
    useState(false);

  const onChangeSwitchDTHandler = (checked: boolean): void => {
    if (checked) {
      setSwitchDistanceTime("distance");
      if (switchPedestrianCar === "car") {
        setSliderValues(["5km", "10km", "15km", "20km"]);
      } else {
        setSliderValues(["0,5km", "1km", "1,5km", "2km"]);
      }
    } else {
      setSwitchDistanceTime("time");
      setSliderValues(["5min", "10min", "15min", "20min"]);
    }
  };
  const onChangeSwitchPCHandler = (checked: boolean): void => {
    if (checked) {
      setSwitchPedestrianCar("car");
      if (switchDistanceTime === "distance") {
        setSliderValues(["5km", "10km", "15km", "20km"]);
      } else {
        setSliderValues(["5min", "10min", "15min", "20min"]);
      }
    } else {
      setSwitchPedestrianCar("pedestrian");
      if (switchDistanceTime === "distance") {
        setSliderValues(["0,5km", "1km", "1,5km", "2km"]);
      } else {
        setSliderValues(["5min", "10min", "15min", "20min"]);
      }
    }
  };

  const onChangeSliderHandler = (value: number | number[]) => {
    if (typeof value === "number") {
      setSliderCurrentValueId(value - 1);
    }
  };
  const preparePolygonQuery = () => {
    let sliderValue: number | null = null;
    if (switchDistanceTime === "distance") {
      sliderValue =
        parseFloat(
          sliderValues[sliderCurrentValueId].split("km")[0].replace(/,/g, ".")
        ) * 1000;
    } else if (switchDistanceTime === "time") {
      sliderValue =
        parseFloat(sliderValues[sliderCurrentValueId].split("min")[0]) * 60;
    }

    return `${
      configData.SERVER_URL
    }/geo/polygon/reachability/stats/pazienti?aslayer=true&targettype='${analysesOption.toLowerCase()}'&transporttype='${switchPedestrianCar}'&measuretype='${switchDistanceTime}'&measure=${sliderValue}`;
  };

  const prepareLayerName = () => {
    let sliderValue: number | null = null;
    if (switchDistanceTime === "distance") {
      sliderValue =
        parseFloat(
          sliderValues[sliderCurrentValueId].split("km")[0].replace(/,/g, ".")
        ) * 1000;
    } else if (switchDistanceTime === "time") {
      sliderValue =
        parseFloat(sliderValues[sliderCurrentValueId].split("min")[0]) * 60;
    }

    return `reachability_${analysesOption.toLowerCase()}_${switchPedestrianCar}_${switchDistanceTime}_${sliderValue}`;
  };

  const changeMapType = (): void => {
    setIsMapExplore((prevState) => !prevState);
  };

  const changeActiveLayers = (
    titles: string[],
    openWorkspace: boolean,
    layers: BoundariesLayer[] | PoiLayer[] | DiseaseLayer[],
    setLayers:
      | React.Dispatch<React.SetStateAction<BoundariesLayer[]>>
      | React.Dispatch<React.SetStateAction<PoiLayer[]>>
      | React.Dispatch<React.SetStateAction<DiseaseLayer[]>>
  ): void => {
    let isLayerChecked = false;
    let updatedLayers: any = [];
    
    if (titles.includes("Emicrania") || titles.includes("Diabete") ){
      layers.map((singleLayer) => {
        const activateLayer = titles.find((title) => singleLayer.name === title);
        const singleLayerIsActive = singleLayer.isChecked;
  
        if (!activateLayer){
          isLayerChecked = false;
        } else if (activateLayer && singleLayerIsActive){
          isLayerChecked = false;
        } else if (activateLayer && !singleLayerIsActive) {
          isLayerChecked = true;
        }
  
        const updatedLayer = {
          ...singleLayer,
          isChecked: isLayerChecked,
        };
        updatedLayers = [...updatedLayers, updatedLayer];
      });
    } else {
      layers.map((singleLayer) => {
        const activateLayer = titles.find((title) => singleLayer.name === title);
        const singleLayerIsActive = singleLayer.isChecked;

        if (!singleLayerIsActive && activateLayer) {
          isLayerChecked = true;
        } else if (singleLayerIsActive && activateLayer && !openWorkspace) {
          isLayerChecked = false;
        } else if (singleLayerIsActive && activateLayer && openWorkspace) {
          isLayerChecked = true;
        } else if (singleLayerIsActive && !activateLayer && !openWorkspace) {
          isLayerChecked = true;
        } else if (singleLayerIsActive && !activateLayer && openWorkspace) {
          isLayerChecked = false;
        } else {
          isLayerChecked = false;
        }

        const updatedLayer = {
          ...singleLayer,
          isChecked: isLayerChecked,
        };
        updatedLayers = [...updatedLayers, updatedLayer];
      });
    }

    if (updatedLayers !== undefined) {
      setLayers([...updatedLayers]);
    }
  };

  const changeActiveBoundaries = (
    titles: string[],
    openWorkspace: boolean
  ): void => {
    changeActiveLayers(titles, openWorkspace, boundaries, setBoundaries);
  };

  const changeActivePoi = (titles: string[], openWorkspace: boolean): void => {
    changeActiveLayers(titles, openWorkspace, poi, setPoi);
  };

  const changeActiveDiseases = (
    titles: string[],
    openWorkspace: boolean
  ): void => {
    console.log("changeActiveDiseases");
    changeActiveLayers(titles, openWorkspace, diseases, setDiseases);
    console.log("changeActiveDiseases - done");
  };

  const clearHeatmap = () => {
    if (heatmapActive) {
      setHeatmapActive(false);
    }
  };

  const onChangeHeatmapHandler = (
    openWorkspace: boolean,
    workspaceStatus?: any
  ): void => {
    // if the heatmap already exist turn it off first
    let isLayerChecked = false;
    if (!openWorkspace && !heatmapActive) {
      isLayerChecked = true;
    } else if (!openWorkspace && heatmapActive) {
      isLayerChecked = false;
    } else if (openWorkspace && workspaceStatus) {
      isLayerChecked = true;
    } else if (openWorkspace && !workspaceStatus) {
      isLayerChecked = false;
    } else if (openWorkspace && !heatmapActive && !workspaceStatus) {
      isLayerChecked = false;
    } else if (openWorkspace && heatmapActive && workspaceStatus) {
      isLayerChecked = true;
    }
    setHeatmapActive(isLayerChecked);
  };

  const takeHeatmapData = () => {
    const activeClusters: string[] = [];
    poi.map((clusterData) => {
      if (clusterData.isChecked) {
        activeClusters.push(clusterData.name);
      }
    });
    let dataUrl="";
    let name;
    if (activeClusters.length > 0) {
      dataUrl = `${configData.SERVER_URL}/geo/point/combinelayers?`;
      let params = "";
      activeClusters.forEach(clstr => {
        switch (clstr) {
          case "Ospedali e cliniche":
            params += "&ospedali_e_cliniche={\"aslid\":[" + configData.ASL + "]}";
            break;
          case "Farmacie":
            params += "&farmacie={\"aslid\":[" + configData.ASL + "]}";
            break;
          case "Studi medici":
            params += "&studi_medici={\"aslid\":[" + configData.ASL + "]}";
            break;
          case "Strutture per attività diagnostica":
            params += "&strutture_per_attività_diagnostica={\"aslid\":[" + configData.ASL + "]}";
            break;
          case "Strutture assistenziali":
            params += "&strutture_assistenziali={\"aslid\":[" + configData.ASL + "]}";
            break;
          case "Laboratori":
            params += "&laboratori={\"aslid\":[" + configData.ASL + "]}";
            break;
          case "Consultori":
            params += "&consultori={\"aslid\":[" + configData.ASL + "]}";
            break;
          default:
            break;
        }
      });
      dataUrl = params.length > 0 ? dataUrl + params.substring(1) : "";
      name = activeClusters.join();
    }
    return { url: dataUrl, heatmapName: name };
  };

  const contextValue: ToolSidebarContextObject = useMemo(
    (): ToolSidebarContextObject => ({
      isMapExplore,
      isMapAnalyze,
      changeMapType,
      changeActiveBoundaries,
      changeActivePoi,
      changeActiveDiseases,
      boundaries,
      poi,
      diseases,
      heatmapActive,
      onChangeHeatmapHandler,
      takeHeatmapData,
      clearHeatmap,
      analysesOption,
      setAnalysesOption,
      onChangeSwitchDTHandler,
      onChangeSwitchPCHandler,
      sliderValues,
      onChangeSliderHandler,
      preparePolygonQuery,
      prepareLayerName,
      setShowReachabilityPolygons,
      showReachabilityPolygons,
    }),
    [
      isMapExplore,
      boundaries,
      poi,
      diseases,
      heatmapActive,
      analysesOption,
      sliderValues,
      sliderCurrentValueId,
      switchDistanceTime,
      switchPedestrianCar,
      showReachabilityPolygons,
    ]
  );
  return (
    <ToolSidebarContext.Provider value={contextValue}>
      {children}
    </ToolSidebarContext.Provider>
  );
};

export default ToolSidebarContextProvider;
