import React, { useContext } from 'react';
import styles from './LegendBox.module.css';
import Card from '../../../../UI/Card';
import { ToolSidebarContext } from '../../../../store/toolsidebar-context';
import Boundaries from './Boundaries';
import Poi from './Poi';
import Diseases from './Diseases';

const LegendBox: React.FC<{legendFontSize:string}> = (props) => {
    const {legendFontSize} = props;
    const toolSidebarCtx = useContext(ToolSidebarContext);

    const wrapperStyles = toolSidebarCtx.isMapExplore
        ? styles.wrapper
        : `${styles.wrapper} ${styles.wrapper_disabled}`;
    const cardStyles = toolSidebarCtx.isMapExplore ? '' : styles.card_disabled;
    return (
        <Card additionalStyles={cardStyles}>
            <div className={wrapperStyles}>
                <div className={styles.wrapper_item}>
                    <h5 className={styles.subtitle}>Confini</h5>
                    <Boundaries 
                    legendFontSize={legendFontSize}/>
                </div>
                <div className={styles.wrapper_item}>
                    <h5 className={styles.subtitle}>Strutture</h5>
                    <Poi 
                    legendFontSize={legendFontSize}
                    />
                </div>
                <div className={styles.wrapper_item}>
                    <h5 className={styles.subtitle}>Patologie</h5>
                    <Diseases 
                    legendFontSize={legendFontSize}
                    />
                </div>
            </div>
        </Card>
    );
};
export default LegendBox;
