import { useState, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../../store/auth-context';

import CircularProgress from '@mui/material/CircularProgress';

import configData from '../../config.json';
import styles from './AuthForm.module.css';

const AuthForm:React.FC = () => {
  const navigate = useNavigate();
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>):void => {
    event.preventDefault();

    const currentUsername:any = usernameInputRef.current;
    const currentPassword:any = passwordInputRef.current;

    const enteredUsername:string = currentUsername.value;
    const enteredPassword:string = currentPassword.value
    
    let formData = new FormData();
    formData.append("username",enteredUsername);
    formData.append("password",enteredPassword);

    // optional: Add validation
    setIsLoading(true);
    const url = `${configData.SERVER_URL}/auth/login`;
    
    fetch(url, {
      method: "POST",
      body:formData
    }
    ).then(response => {
      setIsLoading(false);
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((data) => {
          let errorMessage = 'Authentication failed';
          throw new Error(errorMessage);
        });
      }
    })
      .then((data) => {
        const expirationTime = data.expires;
        const username = data.user.username;
        const usernameId = data.user.id.toString();
        authCtx.login(data.access_token, expirationTime,username,usernameId);
        navigate("/", { replace: true });
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <section className={styles.auth}>
      <h1>Login</h1>
      <form onSubmit={submitHandler}>
        <div className={styles.control}>
          <label htmlFor='username'>Username</label>
          <input type='text' id='username' required ref={usernameInputRef} />
        </div>
        <div className={styles.control}>
          <label htmlFor='password'>Your Password</label>
          <input type='password' id='password' required ref={passwordInputRef} />
        </div>
        <div className={styles.actions}>
          {!isLoading && <button id='loginBtn'>Login</button>}
          {isLoading && <button style={{width:'121.43px'}}><CircularProgress color="inherit" size='1em'/></button>}
        </div>
      </form>
    </section>
  );
};

export default AuthForm;
