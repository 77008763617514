import React, { useContext } from "react";
import { FormControlLabel, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import RectangleIcon from "@mui/icons-material/Rectangle";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import { ToolSidebarContext } from "../../../../store/toolsidebar-context";

const Boundaries: React.FC<{ legendFontSize: string }> = (props) => {
  const { legendFontSize } = props;
  const toolSidebarCtx = useContext(ToolSidebarContext);

  return (
    <div className="sidebarBoxContainer">
      {toolSidebarCtx.boundaries.map((layer) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                {...layer.label}
                icon={<RectangleOutlinedIcon />}
                checkedIcon={<RectangleIcon />}
                onChange={() =>
                  toolSidebarCtx.changeActiveBoundaries([layer.name], false)
                }
                checked={layer.isChecked}
              />
            }
            label={
              <Typography fontSize={legendFontSize}>{layer.name}</Typography>
            }
            key={layer.name}
          />
        );
      })}
    </div>
  );
};

export default Boundaries;
