import {
  LABEL_HEALTH_FACILITIES,
  LABEL_DIAGNOSTICS_FACILITIES,
  LABEL_ASL_BOUNDARIES,
  LABEL_COMUNI_BOUNDARIES,
  LABEL_DIABETIC,
  LABEL_HOSPITALSANDCLINICS,
  LABEL_MEDICAL_OFFICES,
  LABEL_MIGRAINE,
  LABEL_PHARMACIES,
  LABEL_CONSULTANTS,
  LABEL_LABORATORIES,
  COLOR_HOSPITALSANDCLINICS,
  COLOR_PHARMACIES,
  COLOR_HEALTH_FACILITIES,
  COLOR_DIAGNOSTICS_FACILITIES,
  COLOR_MEDICAL_OFFICES,
  COLOR_CONSULTANTS,
  COLOR_LABORATORIES,
  COLOR_MIGRAINE,
} from "./constants";

export const defaultBoundaries = [
  {
    name: "Comuni",
    label: LABEL_COMUNI_BOUNDARIES,
    isChecked: false,
  },
  {
    name: "ASL",
    label: LABEL_ASL_BOUNDARIES,
    isChecked: false,
  },
];
export const defaultPoi = [
  {
    name: "Ospedali e cliniche",
    label: LABEL_HOSPITALSANDCLINICS,
    isChecked: false,
    color: COLOR_HOSPITALSANDCLINICS,
  },
  {
    name: "Farmacie",
    label: LABEL_PHARMACIES,
    isChecked: false,
    color: COLOR_PHARMACIES,
  },
  {
    name: "Studi medici",
    label: LABEL_MEDICAL_OFFICES,
    isChecked: false,
    color: COLOR_MEDICAL_OFFICES,
  },
  {
    name: "Strutture per attività diagnostica",
    label: LABEL_DIAGNOSTICS_FACILITIES,
    isChecked: false,
    color: COLOR_DIAGNOSTICS_FACILITIES,
  },
  {
    name: "Strutture assistenziali",
    label: LABEL_HEALTH_FACILITIES,
    isChecked: false,
    color: COLOR_HEALTH_FACILITIES,
  },
  {
    name: "Laboratori",
    label: LABEL_LABORATORIES,
    isChecked: false,
    color: COLOR_LABORATORIES,
  },
  {
    name: "Consultori",
    label: LABEL_CONSULTANTS,
    isChecked: false,
    color: COLOR_CONSULTANTS,
  },
];
export const defaultDiseases = [
  {
    name: "Emicrania",
    id: "migraine",
    label: LABEL_MIGRAINE,
    isChecked: false,
    color: COLOR_MIGRAINE,
    size: 2000,
  },
  {
    name: "Diabete",
    label: LABEL_DIABETIC,
    isChecked: false,
    color: COLOR_MIGRAINE,
    size: 2000,
  },
];
