import {
  LABEL_HOSPITALSANDCLINICS,
  LABEL_PHARMACIES,
  LABEL_MEDICAL_OFFICES,
  LABEL_DIAGNOSTICS_FACILITIES,
  LABEL_HEALTH_FACILITIES,
  LABEL_LABORATORIES,
  LABEL_CONSULTANTS,
  COLOR_REACHABILITY_POLYGONS,
  OUTLINE_REACHABILITY_POLYGONS,
  LABEL_MIGRAINE,
} from "./constants";
import Basemap from "@arcgis/core/Basemap";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import Locate from "@arcgis/core/widgets/Locate";
import Home from "@arcgis/core/widgets/Home";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Sketch from "@arcgis/core/widgets/Sketch";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import BoundariesLayer from "../models/boundariesLayer";
import { boundaries } from "./boundaries";
import { clusters } from "./cluster";
import { rendererHeatmap } from "./heatmap";
import { diseases, setDiseasesStyle, getDiseasesLayerLegend } from "./diseases";
import { calculations } from "./calculations";
import configData from "../config.json";

// graphics layer that will contain all the polygons drawn by user
const graphicsLayer = new GraphicsLayer();

let highlightLayers: GeoJSONLayer[] = [];

// basemap property is set according to  toolsidebar-context: defaultBaseLayers isChecked property
export const map = new Map({
  basemap: "gray-vector",
  layers: [graphicsLayer],
});

const app = {
  map,
  center: [9.34041, 44.32389],
  scale: 100000,
  constraints: {
    minScale: 500,
    maxScale: 2500000,
  },
  ui: {
    components: ["attribution", "zoom"],
  }, 
  popup: {
    featureViewModels:{

    },
    collapseEnabled:false,
    defaultPopupTemplateEnabled: false,
    includeDefaultActions:false,
    dockOptions: {
      buttonEnabled: false,
      breakpoint: false,
    },
  },
};

const onClickEvents : [ (resp:any) => void ] = [()=>{}];

export const addOnClickEvent = (func : (resp:any) => void ) => {
  onClickEvents.push(func);
};

const runClickEvents = (response:any) => {
  onClickEvents.forEach(f=>{
    f(response);
  });
}

export const view = new MapView(app);
view.popup.autoOpenEnabled = false;

let hl: __esri.Handle | undefined = undefined;

const highlightGraphics = (response:any) => {
  if (hl){
    hl.remove()
  }
  if (response.results.length) {
    const graphic = response.results[0].graphic;
    console.log(response);
    let lv : __esri.GeoJSONLayerView | undefined = undefined;
    if (graphic.layer.title === "Emicrania"){
      lv = view.allLayerViews.find((lv) => {return lv.layer === migraineLayer}) as __esri.GeoJSONLayerView;
    } else if(graphic.layer.title === "Diabete"){
      lv = view.allLayerViews.find((lv) => {return lv.layer === diabeticLayer}) as __esri.GeoJSONLayerView;
    } else if(graphic.layer.title.indexOf("reachability_") === 0){
      console.log('EVET REACH BENIM');
      lv = view.allLayerViews.find((lv) => {return lv.layer === reachabilityLayer}) as __esri.GeoJSONLayerView;
      console.log(lv);
    }
    if (lv){
      hl = lv.highlight(graphic);
    }
  } 
}

const onMapClick = (event: any) => {
  // only include graphics from hurricanesLayer in the hitTest
  const opts = {
    include: highlightLayers
  };
  // the hitTest() checks to see if any graphics from the hurricanesLayer
  // intersect the x, y coordinates of the pointer
  view.hitTest(event, opts).then((response)=> {
    highlightGraphics(response);
    runClickEvents(response);
  });
}

view.on("click", onMapClick);

const basemapGallery = new BasemapGallery({
  view,
  source: [
    Basemap.fromId("gray-vector"),
    Basemap.fromId("dark-gray-vector"),
    Basemap.fromId("topo-vector"),
    Basemap.fromId("satellite"),
  ],
});

// create ui widgets
const bgExpand = new Expand({
  view,
  content: basemapGallery,
  expandIconClass: "esri-icon-basemap",
});

const locateWidget = new Locate({
  view: view,
});

const homeBtn = new Home({
  view: view,
});

let sketch = new Sketch({
  layer: graphicsLayer,
  view: view,
  // graphic will be selected as soon as it is created
  creationMode: "update",
});

sketch.visibleElements = {
  createTools: {
    polyline: false,
    rectangle: false,
  },
  selectionTools: {
    "lasso-selection": false,
    // "rectangle-selection": false
  },
  snappingControlsElements: {
    enabledToggle: false,
    selfEnabledToggle: false,
    featureEnabledToggle: false,
    layerList: false,
  },
  undoRedoMenu: false,
  settingsMenu: false,
  snappingControls: false,
};

// Listen to sketch widget's create event.
sketch.on("create", function (event) {
  if (event.state === "complete") {
    console.log(event);
  }
});

export const initializeMap = async (
  mapContainer: HTMLDivElement
): Promise<void> => {
  view.container = mapContainer;

  // Add the widgets to the map

  view.ui.add(bgExpand, "top-left");
  view.ui.add(locateWidget, "top-left");
  view.ui.add(homeBtn, "top-left");
  // view.ui.add(sketch, "top-right");
};

export const addMapWidgetMargin = (): void => {
  const sidebar = document.body.querySelector(".SidebarTools_sidebar__VwfOn")!;
  const sidebarWidth = window.getComputedStyle(sidebar).width;
  const marginToUse = parseFloat(sidebarWidth.slice(0, -2)) + 12;
  const mapWidgets = document.body.querySelector(
    ".esri-ui-top-left.esri-ui-corner"
  ) as HTMLElement;

  if (mapWidgets !== null) {
    mapWidgets.style.marginLeft = `${marginToUse}px`;
  }
};

export const changeBaseMap = (basemap: Basemap): void => {
  map.basemap = basemap;
};

/* Boundaries */
const comuniUrl = `${configData.SERVER_URL}/geo/polygon/comuni?aslayer=true`;
const aslUrl = `${configData.SERVER_URL}/geo/polygon/asl?aslayer=true&id=${configData.ASL}`;
/* Poi */
//const ospedaliUrl = `${configData.SERVER_URL}/geo/point/ospedali?aslayer=true&aslid=${configData.ASL}`;
const ospedali_e_clinicheUrl = `${configData.SERVER_URL}/geo/point/ospedali_e_cliniche?aslayer=true&aslid=${configData.ASL}`;
const farmacieUrl = `${configData.SERVER_URL}/geo/point/farmacie?aslayer=true&aslid=${configData.ASL}`;
const studi_mediciUrl = `${configData.SERVER_URL}/geo/point/studi_medici?aslayer=true&aslid=${configData.ASL}`;
const strutture_per_attività_diagnosticaUrl = `${configData.SERVER_URL}/geo/point/strutture_per_attività_diagnostica?aslayer=true&aslid=${configData.ASL}`;
const consultoriUrl = `${configData.SERVER_URL}/geo/point/consultori?aslayer=true&aslid=${configData.ASL}`;
const laboratoriUrl = `${configData.SERVER_URL}/geo/point/laboratori?aslayer=true&aslid=${configData.ASL}`;
const strutture_assistenzialiUrl = `${configData.SERVER_URL}/geo/point/strutture_assistenziali?aslayer=true&aslid=${configData.ASL}`;
//Diseases
const emicraniciUrl = `${configData.SERVER_URL}/geo/polygon/hexgrid/250/stats/emicranici?aslayer=true`;
const diabeticiUrl = `${configData.SERVER_URL}/geo/polygon/hexgrid/250/stats/diabetici?aslayer=true`;

const comuniLayer = boundaries.comuni.layer;
const aslLayer = boundaries.asl.layer;

//const hospitals = clusters.hospitals.layer;
const hospitalsAndClinics = clusters.hospitalsAndClinics.layer;
const pharmacies = clusters.pharmacies.layer;
const medicalOffices = clusters.medicalOffices.layer;
const diagnosticsFacilities = clusters.diagnosticsFacilities.layer;
const laboratories = clusters.laboratories.layer;
const healthFacilities = clusters.healthFacilities.layer;
const consultants = clusters.consultants.layer;

export const migraineLayer = diseases.migraine.layer;
export const diabeticLayer = diseases.diabetic.layer;

const reachabilityLayer = calculations.reachability.layer;

export const addDiseases = (name: string, size: number = 2000) => {
  view.ui.remove("disease-legend");

  if (name === "Emicrania") {
    map.remove(diabeticLayer);
    highlightLayers.splice(highlightLayers.indexOf(diabeticLayer),1); 
    highlightLayers.push(migraineLayer);
    migraineLayer.url = emicraniciUrl;
    migraineLayer.title = name;
    setDiseasesStyle(migraineLayer, view);
    migraineLayer.outFields = ["*"];
    migraineLayer.refresh();
    map.add(migraineLayer);
    let legend = getDiseasesLayerLegend(view, migraineLayer, name);
    legend.renderNow();
    view.ui.add(legend, "bottom-left");
  } else if (name === "Diabete") {
    map.remove(migraineLayer);
    highlightLayers.splice(highlightLayers.indexOf(migraineLayer),1); 
    highlightLayers.push(diabeticLayer);
    diabeticLayer.url = diabeticiUrl;
    diabeticLayer.title = name;
    setDiseasesStyle(diabeticLayer, view);
    diabeticLayer.outFields = ["*"];
    diabeticLayer.refresh();
    map.add(diabeticLayer);
    let legend = getDiseasesLayerLegend(view, diabeticLayer, name);
    legend.renderNow();
    view.ui.add(legend, "bottom-left");
  }
};

export const addBoundaries = (name: string) => {
  if (name === "Comuni") {
    comuniLayer.url = comuniUrl;
    comuniLayer.title = name;
    map.add(comuniLayer, 0);
  } else if (name === "ASL") {
    aslLayer.url = aslUrl;
    aslLayer.title = name;
    map.add(aslLayer, 0);
  }
};

export const addReachabilityPolygons = (name: string, url: string): void => {
  reachabilityLayer.url = url;
  reachabilityLayer.title = name;
  reachabilityLayer.outFields = ["*"];
  reachabilityLayer.refresh();
  map.add(reachabilityLayer);
  highlightLayers.push(reachabilityLayer);
};

export const removeLayer = (name: string): void => {
  const layerToRemove = map.layers.find((layer) => layer.title === name);
  if (layerToRemove !== undefined) {
    map.remove(layerToRemove);
    highlightLayers.splice(highlightLayers.indexOf(reachabilityLayer),1); 
    //remove disease legend
    view.ui.remove("disease-legend");
  }
};

const clusterNames = [
  LABEL_HOSPITALSANDCLINICS.inputProps["aria-label"],
  LABEL_PHARMACIES.inputProps["aria-label"],
  LABEL_MEDICAL_OFFICES.inputProps["aria-label"],
  LABEL_DIAGNOSTICS_FACILITIES.inputProps["aria-label"],
  LABEL_HEALTH_FACILITIES.inputProps["aria-label"],
  LABEL_LABORATORIES.inputProps["aria-label"],
  LABEL_CONSULTANTS.inputProps["aria-label"],
];

export const hideClusterLayers = () => {
  map.layers.forEach((layer) => {
    clusterNames.forEach((name) => {
      if (layer.title === name) {
        layer.visible = false;
      }
    });
  });
};

export const showClusterLayers = () => {
  map.layers.forEach((layer) => {
    clusterNames.forEach((name) => {
      if (layer.title === name) {
        layer.visible = true;
      }
    });
  });
};

export const addHeatmap = (name: string, dataUrl: string): void => {
  const poiHeatmap = new GeoJSONLayer({
    url: dataUrl,
    title: name,
    renderer: rendererHeatmap,
  });
  map.add(poiHeatmap);
};

export const addCluster = (name: string, layers: BoundariesLayer[]): void => {
  // make sure cluster layer is visible (because we hide it while using heatmap)
  const makeLayerVisible = (layer: GeoJSONLayer) => {
    if (!layer.visible) {
      layer.visible = true;
    }
  };

  switch (name) {
    case "Ospedali e cliniche":
      hospitalsAndClinics.url = ospedali_e_clinicheUrl;
      hospitalsAndClinics.title = name;
      map.add(hospitalsAndClinics);
      makeLayerVisible(hospitalsAndClinics);
      break;
    case "Farmacie":
      pharmacies.url = farmacieUrl;
      pharmacies.title = name;
      map.add(pharmacies);
      makeLayerVisible(pharmacies);
      break;
    case "Studi medici":
      medicalOffices.url = studi_mediciUrl;
      medicalOffices.title = name;
      map.add(medicalOffices);
      makeLayerVisible(medicalOffices);
      break;
    case "Strutture per attività diagnostica":
      diagnosticsFacilities.url = strutture_per_attività_diagnosticaUrl;
      diagnosticsFacilities.title = name;
      map.add(diagnosticsFacilities);
      makeLayerVisible(diagnosticsFacilities);
      break;
    case "Strutture assistenziali":
      healthFacilities.url = strutture_assistenzialiUrl;
      healthFacilities.title = name;
      map.add(healthFacilities);
      makeLayerVisible(healthFacilities);
      break;
    case "Laboratori":
      laboratories.url = laboratoriUrl;
      laboratories.title = name;
      map.add(laboratories);
      makeLayerVisible(laboratories);
      break;
    case "Consultori":
      consultants.url = consultoriUrl;
      consultants.title = name;
      map.add(consultants);
      makeLayerVisible(consultants);
      break;
    default:
      break;
  }
};

export const handleOverlayLayers = (
  layers: BoundariesLayer[],
  addLayer: (name: string, layers: BoundariesLayer[]) => void,
  removeLayer: (name: string, layers: BoundariesLayer[]) => void
): void => {
  layers.forEach((layer) => {
    if (layer.isChecked) {
      addLayer(layer!.name, layers);
    } else {
      removeLayer(layer!.name, layers);
    }
  });
};

