import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import { ToolSidebarContext } from '../../../../store/toolsidebar-context';

const MaterialUISwitch = styled((Switch))(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
            transform: "translateX(50px)",
            "& + .MuiSwitch-track": {
                backgroundColor: '#6b7d8e',
                opacity:1
                },
            ".MuiSwitch-thumb":{
                '&:before': {
                    // margin:1,
                    marginLeft:2.5,
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                            '#fff'
                        )}" d="M5.146 15.854V16.625Q5.146 16.979 4.885 17.24Q4.625 17.5 4.271 17.5H3.375Q3.021 17.5 2.76 17.24Q2.5 16.979 2.5 16.625V10.146L4.229 5.042Q4.375 4.646 4.708 4.406Q5.042 4.167 5.458 4.167H14.542Q14.958 4.167 15.292 4.406Q15.625 4.646 15.771 5.042L17.5 10.146V16.625Q17.5 16.979 17.24 17.24Q16.979 17.5 16.625 17.5H15.729Q15.375 17.5 15.115 17.24Q14.854 16.979 14.854 16.625V15.854ZM4.958 8.396H15.042L14.229 5.917H5.771ZM4.25 10.146V14.104ZM6.229 13.396Q6.75 13.396 7.115 13.031Q7.479 12.667 7.479 12.146Q7.479 11.625 7.115 11.26Q6.75 10.896 6.229 10.896Q5.708 10.896 5.344 11.26Q4.979 11.625 4.979 12.146Q4.979 12.667 5.344 13.031Q5.708 13.396 6.229 13.396ZM13.771 13.396Q14.292 13.396 14.656 13.031Q15.021 12.667 15.021 12.146Q15.021 11.625 14.656 11.26Q14.292 10.896 13.771 10.896Q13.25 10.896 12.885 11.26Q12.521 11.625 12.521 12.146Q12.521 12.667 12.885 13.031Q13.25 13.396 13.771 13.396ZM4.25 14.104H15.75V10.146H4.25Z"/></svg>')`,
                    },
            }
        }
        },
        width: 90,
        height: 40,
        // padding: 7,
        padding: 8,
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            backgroundColor: '#6b7d8e',
            opacity:1,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                        '#fff'
                    )}" d="M11.25 4.583Q10.562 4.583 10.073 4.104Q9.583 3.625 9.583 2.938Q9.583 2.25 10.073 1.75Q10.562 1.25 11.25 1.25Q11.938 1.25 12.427 1.75Q12.917 2.25 12.917 2.938Q12.917 3.625 12.427 4.104Q11.938 4.583 11.25 4.583ZM5.812 19.167 8.125 7.479 6.75 8.021V10.875H5V6.896L9.083 5.146Q9.75 4.854 10.406 5.042Q11.062 5.229 11.438 5.833L12.271 7.146Q12.812 8.021 13.729 8.573Q14.646 9.125 15.833 9.125V10.875Q14.417 10.875 13.25 10.312Q12.083 9.75 11.292 8.812L10.812 11.208L12.521 12.875V19.167H10.771V14.146L9.125 12.562L7.625 19.167Z"/></svg>')`,
                    left: 12,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#fff'
                )}" d="M5.146 15.854V16.625Q5.146 16.979 4.885 17.24Q4.625 17.5 4.271 17.5H3.375Q3.021 17.5 2.76 17.24Q2.5 16.979 2.5 16.625V10.146L4.229 5.042Q4.375 4.646 4.708 4.406Q5.042 4.167 5.458 4.167H14.542Q14.958 4.167 15.292 4.406Q15.625 4.646 15.771 5.042L17.5 10.146V16.625Q17.5 16.979 17.24 17.24Q16.979 17.5 16.625 17.5H15.729Q15.375 17.5 15.115 17.24Q14.854 16.979 14.854 16.625V15.854ZM4.958 8.396H15.042L14.229 5.917H5.771ZM4.25 10.146V14.104ZM6.229 13.396Q6.75 13.396 7.115 13.031Q7.479 12.667 7.479 12.146Q7.479 11.625 7.115 11.26Q6.75 10.896 6.229 10.896Q5.708 10.896 5.344 11.26Q4.979 11.625 4.979 12.146Q4.979 12.667 5.344 13.031Q5.708 13.396 6.229 13.396ZM13.771 13.396Q14.292 13.396 14.656 13.031Q15.021 12.667 15.021 12.146Q15.021 11.625 14.656 11.26Q14.292 10.896 13.771 10.896Q13.25 10.896 12.885 11.26Q12.521 11.625 12.521 12.146Q12.521 12.667 12.885 13.031Q13.25 13.396 13.771 13.396ZM4.25 14.104H15.75V10.146H4.25Z"/></svg>')`,
                right:12,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
          },
        },
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            boxShadow: 'none',
            margin: -4,
            '&:before': {
                        content: "''",
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                            '#fff'
                        )}" d="M11.25 4.583Q10.562 4.583 10.073 4.104Q9.583 3.625 9.583 2.938Q9.583 2.25 10.073 1.75Q10.562 1.25 11.25 1.25Q11.938 1.25 12.427 1.75Q12.917 2.25 12.917 2.938Q12.917 3.625 12.427 4.104Q11.938 4.583 11.25 4.583ZM5.812 19.167 8.125 7.479 6.75 8.021V10.875H5V6.896L9.083 5.146Q9.75 4.854 10.406 5.042Q11.062 5.229 11.438 5.833L12.271 7.146Q12.812 8.021 13.729 8.573Q14.646 9.125 15.833 9.125V10.875Q14.417 10.875 13.25 10.312Q12.083 9.75 11.292 8.812L10.812 11.208L12.521 12.875V19.167H10.771V14.146L9.125 12.562L7.625 19.167Z"/></svg>')`,
                    },
          }
}));

const SwitchPedestrianCar: React.FC = () => {
    const toolSidebarCtx = useContext(ToolSidebarContext);

    return (
        <FormGroup>
            <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} />} label="" onChange={(event,checked) => toolSidebarCtx.onChangeSwitchPCHandler(checked)}/>
        </FormGroup>
    );
};

export default SwitchPedestrianCar;
