import React, { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToolSidebarContext } from "../../../store/toolsidebar-context";
import { deviceSizes } from "../../../responsive/deviceSizes";
import LegendBox from "./Legend/LegendBox";
import genericStyles from "../Sidebars.module.css";
import styles from "./SidebarTools.module.css";
import { Button, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import AnalyseType from "./ActivityType/AnalyseType";
import CustomSlider from "./ActivityType/CustomSlider";
import Card from "../../../UI/Card";

const SidebarTools: React.FC = () => {
  const toolSidebarCtx = useContext(ToolSidebarContext);
  const isBigScreen = useMediaQuery({ maxWidth: deviceSizes.pc });
  const legendFontSize = isBigScreen ? "0.8rem" : "1rem";

  // const onClickAnalyseHandler = (): void => {
  //   toolSidebarCtx.isMapAnalyze(!toolSidebarCtx.isMapAnalyze);
  // };

  const onClickAnalyseHandler = (): void => {
    toolSidebarCtx.setShowReachabilityPolygons((prev) => !prev); //la versione precedente era questa: toolSidebarCtx.setShowReachabilityPolygons((prev) => !prev);
  };

  const onClickResetMapHandler = () => {
    toolSidebarCtx.setShowReachabilityPolygons(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (value === 1 && newValue === 0) {
      //remove heatmap
      toolSidebarCtx.clearHeatmap();
      //remove all layers
    }
    setValue(newValue);
  };

  
  return (
    <div className={genericStyles.sidebar}>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Esplora" />
        <Tab label="Analizza" />
      </Tabs>

      {value === 0 && <LegendBox legendFontSize={legendFontSize} />}
      {value === 1 && (
        <Card additionalStyles={styles.wrapper}>
          <Box sx={{ paddingX: 2 }}>
            <AnalyseType legendFontSize={legendFontSize} />
            <CustomSlider />
            <Box sx={{ marginX: 2, marginY: 4 }}>
              <Button variant="contained" fullWidth onClick={onClickAnalyseHandler}>
                Calcola | Resetta
              </Button>
            </Box>
            {/* <Box sx={{ marginX: 2, marginY: 4 }}>
              <Button variant="contained" fullWidth onClick={onClickResetMapHandler}>
                Resetta
              </Button>
            </Box> */}
          </Box>
        </Card>
      )}
    </div>
  );
};

export default SidebarTools;
