import Reactm, { useState } from 'react';
import styles from "./Dashboard.module.css";

const RightSidebar = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className={styles.rightSection}>
            <h2> Dati pazienti</h2>
          <div className={styles.datsBox}>
            <div className={styles.dats}>
              <div className={styles.datsValue} onClick={handlePopup}>151021 <i className="fa fa-info-circle"></i></div>
            </div>
            <div className={styles.datsLabel}>Totale pazienti</div>
          </div>

          <div className={styles.datsBox}>
            <div className={styles.dats}>
              <div className={styles.datsValue}>7669</div>
            </div>
            <div className={styles.datsLabel}>Diabetici</div>
          </div>

          <div className={styles.datsBox}>
            <div className={styles.dats}>
              <div className={styles.datsValue}>625</div>
            </div>
            <div className={styles.datsLabel}>Emicranici</div>
          </div>

          <div className={styles.datsBox}>
            <div className={styles.dats}>
              <div className={styles.datsValue}>2733</div>
            </div>
            <div className={styles.datsLabel}>Politerapici</div>
          </div>
    </div>
  );
};

export default RightSidebar;