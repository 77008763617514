import React, { useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { ToolSidebarContext } from "../../../../store/toolsidebar-context";

const Diseases: React.FC<{ legendFontSize: string }> = (props) => {
  const { legendFontSize } = props;
  const toolSidebarCtx = useContext(ToolSidebarContext);
  return (
    <div className="sidebarBoxContainer">
      <FormGroup
        sx={
          toolSidebarCtx.heatmapActive
            ? {
                bgcolor: "rgb(255 255 255 / 8%)",
                opacity: 0.4,
                pointerEvents: "none",
              }
            : {}
        }
      >
        {toolSidebarCtx.diseases.map((layer) => {
          const CustomSwitch = styled(Switch)(({ theme }) => ({
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: layer.color,
              "&:hover": {
                backgroundColor: alpha(
                  layer.color,
                  theme.palette.action.hoverOpacity
                ),
              },
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: layer.color,
            },
          }));
          return (
            <FormControlLabel
              control={
                <CustomSwitch
                  {...layer.label}
                  onChange={() => {
                    toolSidebarCtx.changeActiveDiseases([layer.name], false);
                  }}
                  checked={layer.isChecked}
                />
              }
              label={
                <Typography fontSize={legendFontSize}>{layer.name}</Typography>
              }
              key={layer.name}
            />
          );
        })}
      </FormGroup>
      {/* <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => toolSidebarCtx.onChangeHeatmapHandler(false)}
              checked={toolSidebarCtx.heatmapActive}
            />
          }
          label={<Typography fontSize={legendFontSize}>Heatmap2</Typography>}
        />
      </FormGroup> */}
    </div>
  );
};

export default Diseases;
