import React, { useRef } from "react";
import genericStyles from "../Sidebars.module.css";
// import Workspace from "../../Workspace/Workspace";
import StatsComponent from "../../Stats/StatsComponent"
import 'react-circular-progressbar/dist/styles.css';

import configData from "../../../config.json";
import StatsType from "../../../models/stats";

import { migraineLayer, view, addOnClickEvent } from "../../../utils/map";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import axios from "axios";
import {useCallback,useState} from 'react';


const SidebarAnalyses: React.FC = (props) => {
  //const {stats} = props;
  const extentRef = useRef<__esri.Extent>({} as __esri.Extent);
  const selectionStatsDataRef = useRef<StatsType>({} as StatsType);
  const [statsData,setStatsData] = useState<StatsType>({} as StatsType);
  const loadingRef = useRef<boolean>(false);
  const dataTypeRef = useRef<string>("pazienti");
  const eventAdded = useRef<boolean>(false);
  
  async function getStatsData (lb_x:number,lb_y:number,rt_x:number,rt_y:number,dataType:string|undefined) {
    const data = await axios.get(`${configData.SERVER_URL}/stats/${dataType?dataType:"pazienti"}/byextent/${lb_x.toFixed(5)},${lb_y.toFixed(5)},${rt_x.toFixed(5)},${rt_y.toFixed(5)}`);
    return data;
  }
  
  const handleSelection = (response:any)=> {
    if (response.results.length) {
      const attr = response.results[0].graphic.attributes;
      const iStats = {} as StatsType;

      iStats.eta_17_f = attr.stats_eta_17_f;
      iStats.eta_17_m = attr.stats_eta_17_m;
      iStats.eta_17_totale = attr.stats_eta_17_totale;
      iStats.eta_18_49_f = attr.stats_eta_18_49_f;
      iStats.eta_18_49_m = attr.stats_eta_18_49_m;
      iStats.eta_18_49_totale = attr.stats_eta_18_49_totale;
      iStats.eta_50_64_f = attr.stats_eta_50_64_f;
      iStats.eta_50_64_m = attr.stats_eta_50_64_m;
      iStats.eta_50_64_totale = attr.stats_eta_50_64_totale;
      iStats.eta_65_74_f = attr.stats_eta_65_74_f;
      iStats.eta_65_74_m = attr.stats_eta_65_74_m;
      iStats.eta_65_74_totale = attr.stats_eta_65_74_totale;
      iStats.eta_75_f = attr.stats_eta_75_f;
      iStats.eta_75_m = attr.stats_eta_75_m;
      iStats.eta_75_totale = attr.stats_eta_75_totale;
      iStats.sesso_f = attr.stats_sesso_f;
      iStats.sesso_m = attr.stats_sesso_m;
      iStats.totale = attr.stats_totale;
      selectionStatsDataRef.current = iStats;
      if (dataTypeRef.current == "selezione"){
        setStatsData(iStats);
      }
    } else {
      selectionStatsDataRef.current = {} as StatsType;
    }
  }

  if (eventAdded.current == false) {
    addOnClickEvent(handleSelection);
    eventAdded.current = true;
  }
  const onExtent = useCallback(async () => {
    if (dataTypeRef.current != 'selezione'){
      loadingRef.current = true;
      console.log('%d %d %d %d %s',extentRef.current.xmin, extentRef.current.ymin, extentRef.current.xmax, extentRef.current.ymax, dataTypeRef.current);
      const {data} = await getStatsData(extentRef.current.xmin, extentRef.current.ymin, extentRef.current.xmax, extentRef.current.ymax, dataTypeRef.current);
      // console.log(data);
      setStatsData(data);
      // console.log(statsData);
      loadingRef.current = false;
    } else {
      setStatsData(selectionStatsDataRef.current);
    }
  }, [extentRef.current]);

  const onActiveTabChange = (activeTab: string) => {
    console.log(activeTab);
    dataTypeRef.current = activeTab;
    onExtent();
  }

  reactiveUtils.when(
    () => view.stationary === true,
    () => {
      // Get the new extent of the view only when view is stationary.
      if (view.extent) {
        if (!loadingRef.current) {
          extentRef.current = webMercatorUtils.webMercatorToGeographic(view.extent).extent;
          onExtent();
        }
      }
    }
  );

  return (
    <div className={genericStyles.sidebar}>
      <StatsComponent stats={statsData} onActiveTabChange={onActiveTabChange} /> 
    </div>
  );
};


export default SidebarAnalyses;
