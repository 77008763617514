import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import * as colorRendererCreator from "@arcgis/core/smartMapping/renderers/color";
import Legend from "@arcgis/core/widgets/Legend";
import MapView from "@arcgis/core/views/MapView";

type PopupObject = {
    title: string;
    collapseEnabled :boolean;
    content: any;
};
  
const createPopupTemplate = (
    title: string,
    content: any = null,
    collapseEnabled   : boolean
): PopupObject => {
    return {
        title,
        content,
        collapseEnabled
    };
};
    
export const setDiseasesStyle =(diseasesLayer: __esri.GeoJSONLayer, view: __esri.MapView) => {
    colorRendererCreator
    .createClassBreaksRenderer({
        layer: diseasesLayer,
        field: "stats_totale",
        view: view,
        classificationMethod: "natural-breaks",
        numClasses: 5,
        legendOptions: {
            title: " ",
        },
    }).then((classBreaksRendererResult) => {
        diseasesLayer.renderer = classBreaksRendererResult.renderer;
        diseasesLayer.opacity = 0.75;
    });
};
  
const createDiseasesLayer = (
    copyright: string,
    popupTempl: PopupObject
  ): GeoJSONLayer => {
    return new GeoJSONLayer({
        copyright,
        title: copyright,
        popupTemplate: popupTempl,
    });
};
  
export const getDiseasesLayerLegend = (view: MapView, diseasesLayer: __esri.GeoJSONLayer, ttl:string) => {
    const legend = new Legend({
        id: "disease-legend",
        view,
        layerInfos: [
            {
            layer: diseasesLayer,
            title: ttl,
            //container: ""
            },
        ],
    });
    return legend;
};
  
const popupContent4Stats = [
    {
        type: "expression",
        expressionInfo: {
            expression: `
            var attributes = {};
            attributes['Eta 0-17']=$feature.stats_eta_17_totale;
            attributes['Eta 18-49']=$feature.stats_eta_18_49_totale;
            attributes['Eta 50-64']=$feature.stats_eta_50_64_totale;
            attributes['Eta 65-74']=$feature.stats_eta_65_74_totale;
            attributes['Eta 75-..']=$feature.stats_eta_75_totale;
            attributes['Femmine']=$feature.stats_sesso_f;
            attributes['Maschi']=$feature.stats_sesso_m;
            return {
            type: "media",
            attributes: attributes,
            mediaInfos: [{
                type: "columnchart",
                value: {
                fields: ['Eta 0-17','Eta 18-49','Eta 50-64','Eta 65-74','Eta 75-..']
                }
            }, {
                type: "piechart",
                value: {
                fields: ['Femmine','Maschi']
                }
            }]
            }`
        }
    }
];

const statsPopupTemplate = createPopupTemplate("totale: {stats_totale}",popupContent4Stats, true);

const emicraniciLayer = createDiseasesLayer(
    "emicranici",
    statsPopupTemplate
);

const diabeticiLayer = createDiseasesLayer(
    "diabetici",
    statsPopupTemplate
);

export const diseases = {
    migraine: {
      layer: emicraniciLayer,
    },
    diabetic: {
      layer: diabeticiLayer,
    }
};