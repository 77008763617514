import PatientBarChart from "./PatientBarChart";
import { IStatsWithDelegateProps } from "../../models/stats";
import PatientCount from "./PatientCount";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const StatsComponent: React.FC<IStatsWithDelegateProps> = (props) => {
  
  const {stats, onActiveTabChange} = props;
  const [expanded, setExpanded] = React.useState<string | false>('pazienti');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      onActiveTabChange(panel);
  };
 
  return (
    <div style={{borderRadius:5 ,boxShadow: "0px 0px 3px rgb(0 0 0 / 20%)", margin:10}}>
      <Accordion style={{borderRadius:"5px 5px 0px 0px"}} expanded={expanded === 'pazienti'} onChange={handleChange('pazienti')}>
        <AccordionSummary aria-controls="pazientid-content" id="pazientid-header">
          <Typography>Pazienti (vista)</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:0, minHeight:388}}>
          <PatientCount stats = {stats} title = "pazienti"/>
          <PatientBarChart stats = {stats} title = "pazienti"/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'emicranici'} onChange={handleChange('emicranici')}>
        <AccordionSummary aria-controls="emicranicid-content" id="emicranicid-header">
          <Typography>Emicranici  (vista)</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:0, minHeight:388}}>
          <PatientCount stats = {stats} title = "emicranici"/>
          <PatientBarChart stats = {stats} title = "emicranici"/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'diabetici'} onChange={handleChange('diabetici')}>
        <AccordionSummary aria-controls="diabeticid-content" id="diabeticid-header">
          <Typography>Diabetici (vista)</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:0, minHeight:388}}>
          <PatientCount stats = {stats} title = "diabetici"/>
          <PatientBarChart stats = {stats} title = "diabetici"/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'politerapia'} onChange={handleChange('politerapia')}>
        <AccordionSummary aria-controls="politerapiad-content" id="politerapiad-header">
          <Typography>Politerapici (vista)</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:0, minHeight:388}}>
          <PatientCount stats = {stats} title = "pazienti in politerapia (>5)"/>
          <PatientBarChart stats = {stats} title = "politerapici"/>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{borderRadius:"0px 0px 5px 5px"}} expanded={expanded === 'selezione'} onChange={handleChange('selezione')}>
        <AccordionSummary aria-controls="selezioned-content" id="selezioned-header">
          <Typography>Selezione</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:0}}>
          <PatientCount stats = {stats} title = " nell'area selezionata"/>
          <PatientBarChart stats = {stats} title = "selezione"/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default StatsComponent;
