import React, { useMemo } from "react";
// import { Tooltip as MuiTooltip } from '@mui/material'; // questo import mi produce due import corregerli guide: https://mui.com/material-ui/guides/composition/#caveat-with-refs
import { useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import StatsType, { IChartItem, IStatsProps } from "../../models/stats";
import html2canvas from 'html2canvas';
import { CSVLink } from 'react-csv';
import styles from "./PatientBarChart.module.css";
import { BsFileBarGraph, BsPersonDown} from 'react-icons/bs';
import { RxTable } from 'react-icons/rx';


const convertStatsToChartData = (stats:StatsType) => {
  const data:IChartItem[] = [];
  data.push({
    nome:'0-17',
    femmine:stats.eta_17_f,
    maschi:stats.eta_17_m,
    totale:stats.eta_17_totale
  });
  data.push({
    nome:'18-49',
    femmine:stats.eta_18_49_f,
    maschi:stats.eta_18_49_m,
    totale:stats.eta_18_49_totale
  });
  data.push({
    nome:'50-64',
    femmine:stats.eta_50_64_f,
    maschi:stats.eta_50_64_m,
    totale:stats.eta_50_64_totale
  });
  data.push({
    nome:'65-74',
    femmine:stats.eta_65_74_f,
    maschi:stats.eta_65_74_m,
    totale:stats.eta_65_74_totale
  });
  data.push({
    nome:'75+',
    femmine:stats.eta_75_f,
    maschi:stats.eta_75_m,
    totale:stats.eta_75_totale
  });
  return data;
}

const PatientBarChart : React.FC<IStatsProps> = (props) => {
  // const [data, setData] = useState<chartItem[]>();
  const {stats, title} = props;
  
  const cachedValue = useMemo(() => convertStatsToChartData(stats), [stats])
  // setData();

  const handleDownload = (chartId: string) => {
    const chartElement = document.getElementById(chartId);
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement('a');

        if (chartId.includes("Time")) link.download = title+chartId+'(21/22).png';
        else link.download = title+chartId+'(2022/06).png';

        link.href = canvas.toDataURL();
        link.click();
      });
    } else {
      console.error(`Element with id '${chartId}' not found`);
    }
  };

  const getCSVFilename = (chartName: string) => {
    if (chartName.includes("Time")) return `${chartName}-data(21/22).csv`;
    else return `${chartName}-dati(2022/06).csv`;
  };

  //console.log(cachedValue);
    return (
      // <ResponsiveContainer width="100%" height="100%">
      <div id="SuVistaAgeGroupChart">
        <div className={styles.container}>
          {/*
            <BsPersonDown color="#84817a" style={{ margin: "5px", fontSize: "1.2rem" }} />
           */}
          {/* 
            <BsFileBarGraph color="#84817a" style={{ margin: "5px" }} cursor = "pointer" onClick={() => handleDownload('SuVistaAgeGroupChart')}/>
           */}
          {/* <BsFileBarGraph onClick={downloadImage} color="#84817a" style={{ margin: "5px" }} /> */}
          {/* <CSVLink data={cachedValue} filename={getCSVFilename(title+"SuVista")}>
              <RxTable color="#84817a" style={{ margin: "5px" }}  />
          </CSVLink> */}
        </div>
        <BarChart
          width={250}
          height={200}
          
          data={cachedValue}
          margin={{
            top: 10,
            right: 0,
            left: -5,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="1 2" />
          <XAxis dataKey="nome" tick={{fontSize:12}}/>
          <YAxis tick={{fontSize:13}}/>
          <RechartsTooltip />
          <Legend />
          <Bar dataKey="femmine" stackId="a" fill="#8884d8" />
          <Bar dataKey="maschi" stackId="a" fill="#82ca9d" />
        </BarChart>
      </div>
      // </ResponsiveContainer>
    );
  }
;

export default PatientBarChart;