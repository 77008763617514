import React,{useContext} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { ToolSidebarContext } from '../../../../store/toolsidebar-context';

const AnalysesOptions:React.FC<{legendFontSize:string;}> = (props) => {
  const {legendFontSize} = props;
  const toolSidebarCtx = useContext(ToolSidebarContext);

  return (
    <FormControl sx={{marginBottom:'1em', marginLeft:'1rem'}}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={toolSidebarCtx.analysesOption}
        onChange={(event) => toolSidebarCtx.setAnalysesOption(event.target.value)}
      >

        {toolSidebarCtx.poi.map((element) => {
          return(
            <FormControlLabel key={element.name} value={element.name} control={<Radio />} label={<Typography fontSize={legendFontSize}>{element.name}</Typography>} />
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default AnalysesOptions;
